import * as authService from "../../../auth";

export default {
  namespaced: true,
  state: {
    isAuthToken: null,
    currentUser: null,
    loginHint: "",
  },
  actions: {
    async login({ commit }, payload) {
      return authService
        .loginUser(payload)
        .then((response) => {
          commit("SET_AUTH_USER", {
            token: response.data.token,
            currentUser: response.data.uid,
          });
        })
        .catch((error) => {
          if (
            error?.response?.data ===
            "Your account is disabled! contact super admin."
          ) {
            console.log("Your account is disabled! contact super admin.");
          } else {
            this.snackBar = true;
            this.snackBarText = "Invalid email or password";
          }
        });
    },
    logout(context) {
      context.commit("SET_AUTH_USER", {
        isAuthToken: null,
        currentUser: null,
      });
      authService.logoutUser();
    },
  },
  mutations: {
    SET_AUTH_USER(state, payload) {
      state.isAuthToken = payload.token;
      state.currentUser = payload.currentUser;
    },
    SET_CURRENT_USER(state, payload) {
      state.currentUser = payload;
    },
    SET_LOGIN_HINT(state, payload) {
      state.loginHint = payload;
    },
  },
  getters: {
    isAuthenticated(state) {
      return !!state.isAuthToken;
    },
  },
};
