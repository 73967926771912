import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from './modules/auth/auth'
import employees from './modules/employees/employees'
import definitions from './modules/definitions/definitions'
import deductions from './modules/deductions/deductions'
import statutory from './modules/statutory/statutory'
import  payroll from './modules/payroll/payroll'
import additions from './modules/additions/additions'
import report from './modules/report/report'
import access from './modules/accessLevel/access'
import teamPerformance from './modules/teamPerformance/teamPerformance'

Vue.use(Vuex)

export const storeActions = {
  additions: additions.actions,
}

export const store = new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })],
  state: {
    payrollTab: null,
    accessLevelTab: null
  },
  getters: {
    payrollTab: state => {
      return state.payrollTab || 0
    },
    accessLevelTab: state => {
      return state.accessLevelTab || 0
    }
  },
  mutations: {
    SET_PAYROLL_TAB(state, payload){
      state.payrollTab = payload
    },
    SET_ACCESS_LEVEL_TAB(state, payload){
      state.accessLevelTab = payload
    }

  },
  modules: {
    auth,
    employees,
    definitions,
    deductions,
    statutory,
    payroll,
    additions,
    report,
    access,
    teamPerformance,
  }
})