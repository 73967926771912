import { getCurrentUser } from "../../../auth";
import { apolloClient } from "../../../apollo/apolloVueClient";
import {
  EMPLOYEES_WITH_BONUS_MONTH_DEPT,
  LIST_CURRENT_PAYROLLS_BY_DEPT,
  CURRENT_PAYROLL_STATUS,
  LIST_CURRENT_TIER_THREE_DEPT,
  LIST_CURRENT_NSP_PAYROLLS,
  SEARCH_BY_EMPLOYEE_NAME,
} from "../../../graphql/queries/all_queries";
import {
  AUTHORIZE_PAYROLL,
  APPROVE_PAYROLL,
  GENERATE_PAYROLL,
  RESET_PAYROLL_STATUS,
} from "../../../graphql/mutations/payroll_mutations";
import {
  GET_BONUS_SUMMATIONS_BY_DEPARTMENT,
  GET_NSP_PAYROLL_COLUMN_SUMMATIONS,
  GET_PAYROLL_COLUMN_SUMMATIONS_WITH_DEPARTMENT_AND_YEAR_MONTH_REPORT,
  GET_TIER_ONE_SUMMATIONS_BY_DEPARTMENT,
  GET_TIER_TWO_SUMMATIONS_BY_DEPARTMENT,
} from "../../../graphql/queries/column_summations";

export default {
  namespaced: true,
  state: {
    payroll: [],
    nspPayroll: [],
    payrollBonus: [],
    payrollTotals: {},
    nspPayrollTotals: [],
    tierOneTotals: [],
    tierTwoTotals: [],
    tierThree: [],
    bonusTotals: [],
    currentPage: 0,
    bonusCurrentPage: null,
    totalPages: null,
    bonusTotalPages: null,
    authorizeState: null,
    authorizePayroll: null,
    approveSalary: null,
    resetPayroll: null,
    payrollStatus: null,
    pageLoading: false,
    generating: false,
    approving: false,
    resetting: false,
    currentDepartment: "",
    payrollDate: "",
  },
  getters: {
    pageLoading: (state) => {
      return state.pageLoading;
    },
    currentDepartment: (state) => {
      return state.currentDepartment;
    },
    generating: (state) => {
      return state.generating;
    },
    approving: (state) => {
      return state.approving;
    },
    resetting: (state) => {
      return state.resetting;
    },
    isPayrollStatus: (state) => {
      return state.payrollStatus;
    },
    isAuthorised: (state) => {
      return state.authorizeState;
    },
    resetPayroll: (state) => {
      return state.resetPayroll;
    },
    allPayroll: (state) => {
      return state.payroll;
    },
    allPayrollTotals: (state) => {
      return state.payrollTotals;
    },
    allNspPayroll: (state) => {
      return state.nspPayroll;
    },
    allNspPayrollTotals: (state) => {
      return state.nspPayrollTotals;
    },
    allTierOneTotals: (state) => {
      return state.tierOneTotals;
    },
    allTierTwoTotals: (state) => {
      return state.tierTwoTotals;
    },
    allTierThree: (state) => {
      return state.tierThree;
    },
    allBonusTotals: (state) => {
      return state.bonusTotals;
    },

    allBonus: (state) => {
      return state.payrollBonus;
    },
    hasPayroll: (state) => {
      return state.payroll && state.payroll.length > 0;
    },
    currentPage: (state) => {
      return state.currentPage;
    },
    bonusCurrentPage: (state) => {
      return state.bonusCurrentPage || 1;
    },
    totalPages: (state) => {
      return state.totalPages;
    },
    bonusTotalPages: (state) => {
      return state.bonusTotalPages;
    },
    currentPayrollDate: (state) => {
      return state.payrollDate;
    },
  },
  // mutations
  mutations: {
    SET_CURRENT_DEPARTMENT(state, payload) {
      state.currentDepartment = payload;
    },
    SET_PAYROLL_DATE(state, payload) {
      state.payrollDate = payload;
    },
    SET_PAGE_LOADING(state, payload) {
      state.pageLoading = payload;
    },
    SET_GENERATING(state, payload) {
      state.generating = payload;
    },
    SET_APPROVING(state, payload) {
      state.approving = payload;
    },
    SET_RESETTING(state, payload) {
      state.resetting = payload;
    },
    SET_PAYROLL_STATUS(state, payload) {
      state.payrollStatus = payload;
    },
    SET_AUTHORIZE(state, payload) {
      state.authorizeState = payload;
    },
    SET_RESET_PAYROLL_STATUS(state, payload) {
      state.resetPayroll = payload;
    },

    SET_AUTHORIZE_PAYROLL(state, payload) {
      state.authorizePayroll = payload;
    },
    SET_APPROVE_SALARY(state, payload) {
      state.approveSalary = payload;
    },

    SET_PAYROLL(state, payload) {
      state.payroll = payload;
    },

    SET_NSP_PAYROLL(state, payload) {
      state.nspPayroll = payload;
    },
    SET_PAYROLL_BONUS(state, payload) {
      state.payrollBonus = payload;
    },
    SET_PAYROLL_TOTALS(state, payload) {
      state.payrollTotals = payload;
    },
    SET_NSP_PAYROLL_TOTALS(state, payload) {
      state.nspPayrollTotals = payload;
    },
    SET_TIER_ONE_TOTALS(state, payload) {
      state.tierOneTotals = payload;
    },
    SET_TIER_TWO_TOTALS(state, payload) {
      state.tierTwoTotals = payload;
    },
    SET_TIER_THREE(state, payload) {
      state.tierThree = payload;
    },

    SET_BONUS_TOTALS(state, payload) {
      state.bonusTotals = payload;
    },
    SET_CURRENT_PAGE(state, payload) {
      state.currentPage = payload;
    },
    SET_BONUS_CURRENT_PAGE(state, payload) {
      state.bonusCurrentPage = payload;
    },
    SET_TOTAL_PAGE(state, payload) {
      state.totalPages = payload;
    },
    SET_BONUS_TOTAL_PAGE(state, payload) {
      state.bonusTotalPages = payload;
    },

    SEARCH_PAYROLL_RESULTS(state, payload) {
      state.payroll = payload;
    },
  },

  // actions
  actions: {
    async fetchPayrollStatus(context) {
      try {
        const response = await apolloClient.query({
          query: CURRENT_PAYROLL_STATUS,
          fetchPolicy: "network-only",
        });
        context.commit(
          "SET_PAYROLL_STATUS",
          response.data.listCurrentPayrollStatus.data
        );
      } catch (error) {
        console.log(error);
      }
    },

    // GET STATUS FOR AUTHORIZE BUTTON TO BE TRIGGERED
    async generatePayroll(context) {
      const uuid = getCurrentUser();
      context.commit("SET_GENERATING", true);
      const res = apolloClient.subscribe({
        query: GENERATE_PAYROLL,
        variables: {
          user: uuid.name,
        },
      });

      const generate = res.subscribe({
        next() {
          Fire.$emit(
            "payroll-success",
            `Payroll for ${context.state.payrollDate} has been generated`
          );
        },
        error() {
          context.commit("SET_GENERATING", false);
          Fire.$emit(
            "payroll-error",
            `Failed to generate payroll for ${context.state.payrollDate}`
          );
        },
        complete() {
          context.commit("SET_GENERATING", false);
          generate.unsubscribe();
        },
      });
    },
    async getAuthorizeStatus(context, payload) {
      try {
        const status = await apolloClient.query({
          query: CURRENT_PAYROLL_STATUS,
          variables: {
            yearMonth: payload,
          },
        });
        context.commit("SET_AUTHORIZE", status.data.listCurrentPayrollStatus);
      } catch (error) {
        console.log(error);
      }
    },
    async authorizePayroll(context, payload) {
      const uuid = getCurrentUser();
      const data = {
        authorize: true,
        authorizedBy: uuid.name,
        yearMonth: payload.yearMonth,
      };
      try {
        const response = await apolloClient.mutate({
          mutation: AUTHORIZE_PAYROLL,
          variables: {
            statusInput: data,
          },
        });
        context.commit(
          "SET_AUTHORIZE_PAYROLL",
          response.data.authorizePayroll.data
        );
        Fire.$emit(
          "payroll-success",
          `Payroll for ${payload.yearMonth} has been authorized`
        );
      } catch (error) {
        console.log(error);
        Fire.$emit(
          "payroll-error",
          `Failed to authorize ${payload.yearMonth} payroll`
        );
      }
    },

    async approveSalary(context, payload) {
      const uuid = getCurrentUser();
      const approveData = {
        approve: true,
        approvedBy: uuid.name,
        yearMonth: payload.yearMonth,
      };
      context.commit("SET_APPROVING", true);
      const res = apolloClient.subscribe({
        query: APPROVE_PAYROLL,
        variables: {
          statusInput: approveData,
        },
      });
      const approve = res.subscribe({
        next(data) {
          Fire.$emit(
            "payroll-success",
            `Payroll for ${payload.yearMonth} has been approved`
          );

          context.commit("SET_APPROVE_SALARY", data.data.approvePayroll.data);
        },
        error() {
          Fire.$emit(
            "payroll-error",
            `Failed to approve ${payload.yearMonth} payroll`
          );
        },
        complete() {
          context.commit("SET_APPROVING", false);
          approve.unsubscribe();
        },
      });
    },

    async resetPayrollStatus(context, payload) {
      const payrollData = {
        year: parseInt(payload.year),
        month: parseInt(payload.month),
      };
      context.commit("SET_RESETTING", true);
      try {
        const response = await apolloClient.mutate({
          mutation: RESET_PAYROLL_STATUS,
          fetchPolicy: "network-only",
          variables: {
            year: payrollData.year,
            month: payrollData.month,
          },
        });
        Fire.$emit(
          "payroll-success",
          `Payroll for ${payrollData.year}-${payrollData.month} has been reset`
        );
        context.commit(
          "SET_RESET_PAYROLL_STATUS",
          response.data.rollbackPayroll
        );
		context.commit("SET_RESETTING", false);
      } catch (error) {
        console.log(error);
        Fire.$emit(
          "payroll-error",
          `Failed to reset payroll for ${payrollData.year}-${payrollData.month}`
        );
        context.commit("SET_RESETTING", false);
      }
    },

    async fetchCurrentPayroll({ commit }, { department, page }) {
      //GET ALL CURRENT PAYROLL FROM SERVER
      apolloClient
        .query({
          fetchPolicy: "network-only",
          query: LIST_CURRENT_PAYROLLS_BY_DEPT,
          variables: {
            startWith: department,
            page: page - 1,
            size: 20,
          },
        })
        .then((response) => {
          commit(
            "SET_PAYROLL",
            response.data.listCurrentPayrollsByDepartment.data
          );
          commit(
            "SET_TOTAL_PAGE",
            response.data.listCurrentPayrollsByDepartment.pageable.totalPages
          );
          // /commit("SET_PAGE_LOADING", false);
        })
        .catch();
    },

    async fetchCurrentNspPayroll({ commit }, { page, yearMonth, searchName }) {
      //GET ALL CURRENT PAYROLL FROM SERVER
      apolloClient
        .query({
          fetchPolicy: "network-only",
          query: LIST_CURRENT_NSP_PAYROLLS,
          variables: {
            page: page - 1,
            yearMonth,
            searchName: searchName,
            size: 20,
          },
        })
        .then((response) => {
          commit("SET_NSP_PAYROLL", response.data.listCurrentNspPayroll.data);
          commit(
            "SET_TOTAL_PAGE",
            response.data.listCurrentNspPayroll.pageable.totalPages
          );
          // /commit("SET_PAGE_LOADING", false);
        })
        .catch();
    },
    async fetchCurrentPayrollBonus({ commit }, { department, page }) {
      //GET ALL PAYROLL TOTAL FROM SERVER
      try {
        let response = await apolloClient.query({
          query: EMPLOYEES_WITH_BONUS_MONTH_DEPT,
          variables: {
            startWithInput: department,
            page: page - 1,
            size: 20,
          },
        });
        commit(
          "SET_PAYROLL_BONUS",
          response?.data?.listEmployeesWithBonusForCurrentMonthWithDepartment
        );
        commit(
          "SET_BONUS_TOTAL_PAGE",
          response?.data?.listEmployeesWithBonusForCurrentMonthWithDepartment
            .totalPages
        );
      } catch (error) {
        console.error(error);
      }
    },

    async fetchCurrentPayrollTotals({ commit }, payload) {
      //GET ALL PAYROLL TOTALS FROM SERVER
      try {
        let response = await apolloClient.mutate({
          mutation:
            GET_PAYROLL_COLUMN_SUMMATIONS_WITH_DEPARTMENT_AND_YEAR_MONTH_REPORT,
          variables: {
            startWith: payload.department,
            yearMonth: payload.yearMonth,
            page: payload.page - 1,
            size: 20,
          },
        });
        const requestQuery =
          response.data.getDepartmentPayrollColumnSummationsReport;
        commit("SET_PAYROLL_TOTALS", requestQuery);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchNspTotals({ commit }, yearMonth) {
      try {
        let response = await apolloClient.query({
          query: GET_NSP_PAYROLL_COLUMN_SUMMATIONS,
          variables: {
            yearMonth,
          },
          fetchPolicy: "network-only",
        });
        const requestQuery = response.data.getCurrentNspPayrollColumnSummations;
        commit("SET_NSP_PAYROLL_TOTALS", requestQuery);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchTierOneTotals({ commit }, payload) {
      //GET ALL PAYROLL TOTALS FROM SERVER
      try {
        let response = await apolloClient.query({
          query: GET_TIER_ONE_SUMMATIONS_BY_DEPARTMENT,
          variables: {
            startWith: payload.department,
            yearMonth: payload.yearMonth,
          },
        });
        const requestQuery = response.data.getTierOneSummationByDepartment;
        commit("SET_TIER_ONE_TOTALS", requestQuery);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchTierTwoTotals({ commit }, payload) {
      //GET ALL PAYROLL TOTALS FROM SERVER

      try {
        let response = await apolloClient.query({
          query: GET_TIER_TWO_SUMMATIONS_BY_DEPARTMENT,
          variables: {
            startWith: payload.department,
            yearMonth: payload.yearMonth,
          },
        });
        const requestQuery = response.data.getTierTwoSummationByDepartment;
        commit("SET_TIER_TWO_TOTALS", requestQuery);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchTierThree({ commit }, department) {
      //GET ALL PAYROLL TOTALS FROM SERVER
      try {
        let response = await apolloClient.query({
          query: LIST_CURRENT_TIER_THREE_DEPT,
          variables: {
            startWith: department,
            page: 0,
            size: 20,
          },
        });
        commit(
          "SET_TIER_THREE",
          response.data.listCurrentTierThreeForDepartment.data
        );
      } catch (error) {
        console.log(error);
      }
    },

    async fetchBonusTotals({ commit }, payload) {
      //GET ALL PAYROLL TOTALS FROM SERVER
      try {
        let response = await apolloClient.query({
          query: GET_BONUS_SUMMATIONS_BY_DEPARTMENT,
          variables: {
            startWith: payload.department,
            yearMonth: payload.yearMonth,
          },
        });
        commit("SET_BONUS_TOTALS", response.data.getBonusTotals);
      } catch (error) {
        console.log(error);
      }
    },
    async searchPayrollResults(
      { commit },
      { query, payload, page, department }
    ) {
      //SEARCH PAYROLL RESULTS ON SERVER
      try {
        let response = await apolloClient.query({
          query: SEARCH_BY_EMPLOYEE_NAME,
          fetchPolicy: "network-only",
          variables: {
            searchName: query || null,
            startWith: department,
            yearMonth: payload,
            page: page - 1,
            size: 20,
          },
        });
        let queryResult = response.data.listByEmployeeName.data;
        commit("SEARCH_PAYROLL_RESULTS", queryResult);
        commit(
          "SET_TOTAL_PAGE",
          response.data.listByEmployeeName.pageable.totalPages
        );
      } catch (error) {
        console.log(error);
      }
    },

    async searchBonusResults({ commit }, { query, payload }) {
      //SEARCH PAYROLL RESULTS ON SERVER
      try {
        let response = await apolloClient.query({
          query: SEARCH_BY_EMPLOYEE_NAME,
          variables: {
            searchName: query || null,
            yearMonth: payload.yearMonth,
            startWith: payload.department,
            page: 0,
            size: 20,
          },
        });
        if (response.status == 200 || response.status !== 204) {
          let queryResult = response.data.listByEmployeeName.data;
          commit("SEARCH_PAYROLL_RESULTS", queryResult);
          commit(
            "SET_TOTAL_PAGE",
            response.data.listByEmployeeName.pageable.totalPages
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
