import {
  EMPLOYEE_SORTED_PAYROLL_REPORT,
  MONTHLY_PAYROLL_REPORT,
  SEARCH_BY_EMPLOYEE_NAME,
  EXPORT_PAYROLL_BY_DATE,
  LIST_CURRENT_PAYROLLS_BY_DEPT,
  AMALITECH_GHANA_REPORT,
  AMALITECH_TRAINING_REPORT,
} from "../../../graphql/queries/all_queries";
import { apolloClient } from "../../../apollo/apolloVueClient";
import { GET_PAYROLL_COLUMN_SUMMATIONS_WITH_DEPARTMENT_AND_YEAR_MONTH_REPORT } from "../../../graphql/queries/column_summations";
import { NSP_INDIVIDUAL_PAYSLIP } from "../../../graphql/queries/payroll_tier_queries";

export default {
  namespaced: true,
  // initial state
  state: {
    payrollReport: [],
    masterPayrollTotals: {},
    bankAdviceReport: [],
    tierOne: [],
    tierTwo: [],
    gra: [],
    currentPage: null,
    totalPages: null,
    currentPayslipTab: null,
    individualPayroll: [],
    nspIndividualPayroll: [],
  },

  getters: {
    allPayrollReport: (state) => {
      return state.payrollReport;
    },
    getMasterPayrollTotals: (state) => {
      return state.masterPayrollTotals;
    },
    individualPayroll: (state) => {
      return state.individualPayroll;
    },

    nspIndividualPayroll: (state) => {
      return state.nspIndividualPayroll;
    },

    allBankAdviceReport: (state) => {
      return state.bankAdviceReport;
    },

    allTierOneReport: (state) => {
      return state.tierOne;
    },
    allTierTwoReport: (state) => {
      return state.tierTwo;
    },
    allGraReport: (state) => {
      return state.gra;
    },
    currentPage: (state) => {
      return state.currentPage || 1;
    },
    currentTab: (state) => {
      return state.currentPayslipTab || "monthly";
    },
    totalPages: (state) => {
      return state.totalPages;
    },
  },
  mutations: {
    SET_INDIVIDUAL_PAYSLIP(state, payload) {
      state.individualPayroll = payload;
    },
    SET_NSP_INDIVIDUAL_PAYSLIP(state, payload) {
      state.nspIndividualPayroll = payload;
    },
    SET_PAYROLL(state, payload) {
      state.payrollReport = payload;
    },
    SET_MASTER_PAYROLL_TOTALS(state, payload) {
      state.masterPayrollTotals = payload;
    },
    SET_CURRENT_TAB(state, payload) {
      state.currentPayslipTab = payload;
    },
    SET_BANK_ADVICE(state, payload) {
      state.bankAdviceReport = payload;
    },
    SET_TIER_ONE(state, payload) {
      state.tierOne = payload;
    },
    SET_TIER_TWO(state, payload) {
      state.tierTwo = payload;
    },
    SET_GRA(state, payload) {
      state.payrollReport = payload;
    },
    SET_CURRENT_PAGE(state, payload) {
      state.currentPage = payload;
    },
    SET_TOTAL_PAGE(state, payload) {
      state.totalPages = payload;
    },
    SEARCH_PAYROLL(state, payload) {
      state.payrollReport = payload;
    },
    SEARCH_TIER_ONE(state, payload) {
      state.tierOne = payload;
    },
    SEARCH_TIER_TWO(state, payload) {
      state.tierTwo = payload;
    },
    SEARCH_GRA(state, payload) {
      state.payrollReport = payload;
    },
    SEARCH_BANK_ADVICE(state, payload) {
      state.bankAdviceReport = payload;
    },
  },

  actions: {
    async fetchIndividualPayslip({ commit }, payload) {
      const data = {
        employeeId: payload.employeeId,
        endYearMonth: payload.endYearMonth,
        startYearMonth: payload.startYearMonth,
      };

      return new Promise((resolve, reject) => {
        apolloClient
          .query({
            query: EMPLOYEE_SORTED_PAYROLL_REPORT,
            fetchPolicy: "network-only",
            variables: {
              sortInput: data,
            },
          })
          .then((response) => {
            commit(
              "SET_INDIVIDUAL_PAYSLIP",
              response?.data?.getSortedPayrollReportForEmployee
            );
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    async fetchNspIndividualPayslip({ commit }, payload) {
      const data = {
        employeeId: payload.employeeId,
        endYearMonth: payload.endYearMonth,
        startYearMonth: payload.startYearMonth,
      };
      return new Promise((resolve, reject) => {
        apolloClient
          .query({
            query: NSP_INDIVIDUAL_PAYSLIP,
            variables: {
              sortInput: data,
            },
          })
          .then((response) => {
            commit(
              "SET_NSP_INDIVIDUAL_PAYSLIP",
              response?.data?.getSortedPayrollReportForNSP
            );
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    async fetchPayrollReport({ commit }, payload) {
      try {
        const yearMonth = payload.data.split("-");
        let response = await apolloClient.query({
          query: MONTHLY_PAYROLL_REPORT,
          variables: {
            page: payload.page - 1,
            size: 20,
            monthYear: {
              month: parseInt(yearMonth[1]),
              year: parseInt(yearMonth[0]),
            },
            fetchPolicy: "network-only",
          },
        });
        const queryData = response.data.listPayrollsByMonthAndYear;
        commit("SET_PAYROLL", queryData.data);
        commit("SET_TOTAL_PAGE", queryData.pageable.totalPages);
        commit("SET_CURRENT_PAGE", queryData.pageable.number + 1);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchAmalitechGraReport({ commit }, payload) {
      try {
        const yearMonth = payload.yearMonth.split("-");
        let reportType =
          payload.department === "TRN"
            ? AMALITECH_TRAINING_REPORT
            : AMALITECH_GHANA_REPORT;
        let variables = {
          page: payload.page - 1,
          size: 20,
          yearMonth: yearMonth[0] + "-" + yearMonth[1],
          searchName: payload.searchName,
        };
        let response = await apolloClient.query({
          query: reportType,
          variables: variables,
          fetchPolicy: "network-only",
        });
        const queryData =
          payload.department === "TRN"
            ? response.data.listAmalitechTrainingPayroll
            : response.data.listAmalitechGhanaPayroll;
        commit("SET_GRA", queryData?.data);
        commit("SET_TOTAL_PAGE", queryData?.pageable.totalPages);
        commit("SET_CURRENT_PAGE", queryData?.pageable.number + 1);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchMasterPayrollTotals({ commit }, payload) {
      try {
        let response = await apolloClient.mutate({
          fetchPolicy: "network-only",
          mutation:
            GET_PAYROLL_COLUMN_SUMMATIONS_WITH_DEPARTMENT_AND_YEAR_MONTH_REPORT,
          variables: payload.data,
        });
        commit(
          "SET_MASTER_PAYROLL_TOTALS",
          response.data.getDepartmentPayrollColumnSummationsReport
        );
      } catch (error) {
        console.log(error);
      }
    },

    async fetchPayrollReportByDepartment({ commit }, { department, page }) {
      apolloClient
        .query({
          fetchPolicy: "network-only",
          query: LIST_CURRENT_PAYROLLS_BY_DEPT,
          variables: {
            startWith: department,
            page: page - 1,
            size: 20,
          },
        })
        .then((response) => {
          commit(
            "SET_PAYROLL",
            response.data.listCurrentPayrollsByDepartment.data
          );
          commit(
            "SET_TOTAL_PAGE",
            response.data.listCurrentPayrollsByDepartment.pageable.totalPages
          );
        })
        .catch();
    },

    async fetchBankAdviceReport({ commit }, payload) {
      try {
        const [year, month] = payload.data.yearMonth.split("-");
        let response = await apolloClient.query({
          query: MONTHLY_PAYROLL_REPORT,
          variables: {
            page: payload.page - 1,
            size: 20,
            monthYear: {
              month: parseInt(month),
              year: parseInt(year),
            },
          },
        });
        commit(
          "SET_BANK_ADVICE",
          response?.data?.listPayrollsByMonthAndYear?.data
        );
        commit(
          "SET_TOTAL_PAGE",
          response.data.listPayrollsByMonthAndYear.pageable.totalPages
        );
      } catch (error) {
        console.log(error);
      }
    },

    async fetchTierOneReport({ commit }, payload) {
      const [year, month] = payload.data.yearMonth.split("-");
      return new Promise((resolve, reject) => {
        apolloClient
          .query({
            query: MONTHLY_PAYROLL_REPORT,
            variables: {
              page: payload.page - 1,
              size: 20,
              monthYear: {
                month: parseInt(month),
                year: parseInt(year),
              },
            },
          })
          .then((response) => {
            commit(
              "SET_TIER_ONE",
              response?.data?.listPayrollsByMonthAndYear?.data
            );
            commit(
              "SET_TOTAL_PAGE",
              response?.data?.listPayrollsByMonthAndYear?.pageable?.totalPages
            );
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    async fetchTierTwoReport({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const [year, month] = payload.data.yearMonth.split("-");
        apolloClient
          .query({
            query: MONTHLY_PAYROLL_REPORT,
            variables: {
              page: payload.page - 1,
              size: 20,
              monthYear: {
                month: parseInt(month),
                year: parseInt(year),
              },
            },
          })
          .then((response) => {
            commit(
              "SET_TIER_TWO",
              response?.data?.listPayrollsByMonthAndYear?.data
            );
            commit(
              "SET_TOTAL_PAGE",
              response?.data?.listPayrollsByMonthAndYear?.pageable?.totalPages
            );
            resolve(response);
          })
          .catch((error) => {
            reject(error);
            console.log(error);
          });
      });
    },

    async fetchGraReport({ commit }, { page, data }) {
      const [year, month] = data.yearMonth.split("-");
      try {
        let response = await apolloClient.query({
          query: MONTHLY_PAYROLL_REPORT,
          variables: {
            page: page - 1,
            size: 20,
            monthYear: {
              month: parseInt(month),
              year: parseInt(year),
            },
          },
        });
        commit("SET_GRA", response?.data?.listPayrollsByMonthAndYear?.data);
        commit(
          "SET_TOTAL_PAGE",
          response?.data?.listPayrollsByMonthAndYear?.pageable?.totalPages
        );
      } catch (error) {
        console.log(error);
      }
    },

    async searchEmployeesOnPayrollReport({ commit }, { query, page, data }) {
      try {
        let response = await apolloClient.query({
          query: SEARCH_BY_EMPLOYEE_NAME,
          variables: {
            searchName: query || null,
            yearMonth: data.yearMonth,
            startWith: data.startWith,
            page: page - 1,
            size: 20,
          },
        });
        commit("SEARCH_PAYROLL", response.data.listByEmployeeName.data);
        commit(
          "SET_CURRENT_PAGE",
          response.data.listByEmployeeName.pageable.number
        );
        commit(
          "SET_TOTAL_PAGE",
          response.data.listByEmployeeName.pageable.totalPages
        );
      } catch (error) {
        console.log(error);
      }
    },

    async searchTierOneOnPayrollReport({ commit }, { query, data }) {
      let page = 0;
      try {
        let response = await apolloClient.query({
          query: SEARCH_BY_EMPLOYEE_NAME,
          variables: {
            searchName: query || null,
            yearMonth: data.yearMonth,
            startWith: data.startWith,
            page: page,
            size: 20,
          },
        });
        commit("SEARCH_TIER_ONE", response.data.listByEmployeeName.data);
        commit(
          "SET_TOTAL_PAGE",
          response.data.listByEmployeeName.pageable.totalPages
        );
      } catch (error) {
        console.log(error);
      }
    },

    async searchTierTwoOnPayrollReport({ commit }, { query, data }) {
      try {
        let response = await apolloClient.query({
          query: SEARCH_BY_EMPLOYEE_NAME,
          variables: {
            searchName: query || null,
            yearMonth: data.yearMonth,
            startWith: data.startWith,
            page: data.page - 1,
            size: 20,
          },
        });
        commit("SEARCH_TIER_TWO", response.data.listByEmployeeName.data);
        commit(
          "SET_TOTAL_PAGE",
          response.data.listByEmployeeName.pageable.totalPages
        );
      } catch (error) {
        console.log(error);
      }
    },

    async searchGraPayrollReport({ commit }, payload) {
      try {
        let response = await apolloClient.query({
          query: SEARCH_BY_EMPLOYEE_NAME,
          variables: {
            searchName: payload.query ? payload.query : null,
            yearMonth: payload.yearMonth,
            startWith: payload.startWith,
            page: payload.page - 1,
            size: 20,
          },
        });
        commit("SEARCH_GRA", response.data.listByEmployeeName.data);
        commit(
          "SET_CURRENT_PAGE",
          response.data.listByEmployeeName.pageable.number
        );
        commit(
          "SET_TOTAL_PAGE",
          response.data.listByEmployeeName.pageable.totalPages
        );
      } catch (error) {
        console.log(error);
      }
    },

    async searchForAnEmployeeOnBankAdviceReport(
      { commit },
      { query, page, data }
    ) {
      try {
        let response = await apolloClient.query({
          query: SEARCH_BY_EMPLOYEE_NAME,
          variables: {
            searchName: query || null,
            yearMonth: data.yearMonth,
            startWith: data.startWith,
            page: page - 1,
            size: 20,
          },
        });
        commit("SEARCH_BANK_ADVICE", response.data.listByEmployeeName.data);
        commit(
          "SET_CURRENT_PAGE",
          response.data.listByEmployeeName.pageable.number
        );
        commit(
          "SET_TOTAL_PAGE",
          response.data.listByEmployeeName.pageable.totalPages
        );
      } catch (error) {
        console.log(error);
      }
    },

    async filterPayrollReport({ commit }, payload) {
      try {
        let response = await apolloClient.query({
          query: SEARCH_BY_EMPLOYEE_NAME,
          variables: {
            searchName: payload.name ? payload.name : null,
            yearMonth: payload.yearMonth,
            startWith: payload.startWith,
            page: payload.page - 1,
            size: 20,
          },
        });
        commit("SEARCH_PAYROLL", response.data.listByEmployeeName.data);
        commit(
          "SET_TOTAL_PAGE",
          response.data.listByEmployeeName.pageable.totalPages
        );
      } catch (error) {
        console.error(error);
      }
    },

    async filterBankAdviceReport({ commit }, { name, yearMonth, page }) {
      try {
        let response = await apolloClient.query({
          query: EXPORT_PAYROLL_BY_DATE,
          variables: {
            searchName: name,
            yearMonth: yearMonth,
            page: page - 1,
            size: 20,
          },
        });
        commit("SEARCH_BANK_ADVICE", response.data.exportPayrollByDate.data);
        commit(
          "SET_CURRENT_PAGE",
          response.data.exportPayrollByDate.pageable.number
        );
        commit(
          "SET_TOTAL_PAGE",
          response.data.exportPayrollByDate.pageable.totalPages
        );
      } catch (error) {
        console.error(error);
      }
    },

    async filterTierOneReport({ commit }, payload) {
      try {
        let response = await apolloClient.query({
          query: SEARCH_BY_EMPLOYEE_NAME,
          variables: {
            searchName: payload.searchName || null,
            startWith: payload.startWith,
            yearMonth: payload.yearMonth,
            page: payload.page - 1,
            size: 20,
          },
        });
        commit("SEARCH_TIER_ONE", response.data.listByEmployeeName.data);
        commit(
          "SET_CURRENT_PAGE",
          response.data.listByEmployeeName.pageable.number
        );
        commit(
          "SET_TOTAL_PAGE",
          response.data.listByEmployeeName.pageable.totalPages
        );
      } catch (error) {
        console.error(error);
      }
    },

    async filterTierTwoReport({ commit }, payload) {
      try {
        let response = await apolloClient.query({
          query: SEARCH_BY_EMPLOYEE_NAME,
          variables: {
            searchName: payload.searchName || null,
            startWith: payload.startWith,
            yearMonth: payload.yearMonth,
            page: payload.page - 1,
            size: 20,
          },
        });
        commit("SEARCH_TIER_TWO", response.data.listByEmployeeName.data);
        commit(
          "SET_CURRENT_PAGE",
          response.data.listByEmployeeName.pageable.number
        );
        commit(
          "SET_TOTAL_PAGE",
          response.data.listByEmployeeName.pageable.totalPages
        );
      } catch (error) {
        console.error(error);
      }
    },

    async filterGraReport({ commit }, payload) {
      try {
        let response = await apolloClient.query({
          query: SEARCH_BY_EMPLOYEE_NAME,
          variables: {
            searchName: payload.searchName || null,
            startWith: payload.startWith,
            yearMonth: payload.yearMonth,
            page: payload.page - 1,
            size: 20,
          },
        });
        commit("SEARCH_GRA", response.data.listByEmployeeName.data);
        commit(
          "SET_CURRENT_PAGE",
          response.data.listByEmployeeName.pageable.number
        );
        commit(
          "SET_TOTAL_PAGE",
          response.data.listByEmployeeName.pageable.totalPages
        );
      } catch (error) {
        console.error(error);
      }
    },
  },
};
