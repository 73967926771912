<template>
    <v-dialog v-model="dialog" max-width="600px">
        <v-card>
            <v-card-title>
                <span class="text-h5">Settings</span>
            </v-card-title>
            <v-card-text @click.prevent="toAccessLevel" style="cursor: pointer">
                <v-icon>mdi-account</v-icon>
                <span class="mb-0" color="white">Access Level</span>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
  
<script>
export default {
    props: {
        dialog: {
            default: false,
            type: Boolean,
        }
    },
    methods: {
         toAccessLevel() {
            if (this.$route.path !== "/access-level") {
                this.$router.push("/access-level");
            }

        }
    }
};
</script>
  