import gql from "graphql-tag";

export const CREATE_BONUSES = gql`
  mutation CreateBonuses($createBonusInput: [CreateBonusInput!]!) {
    createBonuses(createBonusInput: $createBonusInput) {
      message
      success
      code
      data {
        id
        employeeId
        employeeNumber
        description
        startDate
        endDate
        totalBonus
        monthlyBonus
        displayName
        date
      }
    }
  }
`;

export const UPDATE_BONUSES = gql`
  mutation UpdateBonuses($updateBonusInput: [BonusInput!]!) {
    updateBonuses(updateBonusInput: $updateBonusInput) {
      message
      success
      code
      data {
        id
        employeeId
        employeeNumber
        description
        startDate
        endDate
        totalBonus
        monthlyBonus
        displayName
        date
      }
    }
  }
`;

export const DELETE_BONUS = gql`
  mutation DeleteBonus($deleteByIdInput: DeleteByIdInput!) {
    deleteBonus(deleteByIdInput: $deleteByIdInput) {
      message
      success
      code
      data {
        id
        employeeId
        employeeNumber
        description
        startDate
        endDate
        totalBonus
        monthlyBonus
        displayName
        date
      }
    }
  }
`;

