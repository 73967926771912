import gql from "graphql-tag";

export const CREATE_ALLOWANCE = gql`
	mutation CreateAllowances($allowanceInput: [AllowanceInput!]!) {
		createAllowances(
			allowanceInput: $allowanceInput
		) {
			message
			success
			code
		}
	}
`;

export const UPDATE_ALLOWANCE = gql`
	mutation UpdateAllowances($allowanceInput: [AllowanceInput!]!) {
		updateAllowances(allowanceInput: $allowanceInput) {
			message
			success
			code
		}
	}
`;

export const DELETE_ALLOWANCE = gql`
	mutation DeleteAllowances($allowanceInput: [DeleteByIdInput!]!) {
		deleteAllowances(allowanceInput: $allowanceInput) {
			message
			success
			code
		}
	}
`;