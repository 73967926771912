<template>
  <v-app class="app">
    <nav-bar />
    <v-main>
      <v-container class=" pt-7 px-2" fluid>
        <slot></slot>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/layouts/NavBar.vue';


export default {
  name: 'Layout',
  components: {
    NavBar,
  },
};
</script>
