import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import 'bootstrap/dist/css/bootstrap.css'
import router from './router'
import {store} from './services/store'
import Spinner from './components/UtilityComponents/SpinnerComponent.vue'
import Snackbar from './components/UtilityComponents/SnackBar.vue'
import Paginate from './components/PaginateComponent.vue'
import ConfirmComponent from './components/ConfirmComponent.vue'
import VueCompositionAPI from '@vue/composition-api'
import Print from 'vue-print-nb'
import fileDownload from "js-file-download";
import { Form } from "vform";
import moment from 'moment'
import apolloProvider from './services/apollo/apolloVueClient'
import VueApollo from 'vue-apollo'

window.Form = Form;
window.Fire = new Vue();
Vue.config.productionTip = false
Vue.use(Print);
Vue.use(VueCompositionAPI)
Vue.use(VueApollo)
Vue.component('spinner', Spinner)
Vue.component('snackbar', Snackbar)
Vue.component('paginate', Paginate)
Vue.component('ConfirmComponent', ConfirmComponent)
Vue.filter('numberDate', function (text) {
  return moment(text).format('YYYY-MM-DD')
})
Vue.filter('yearMonth', function (text) {
  return moment(text).format('MMMM YYYY')
})
Vue.filter('myDate', function (text) {
  return moment(text).format('Do MMM YYYY')
})
Vue.filter('shortDate', function (text) {
  return moment(text).format('Do MMM YY')
})
Vue.filter('myDateTime', function (text) {
  return moment(text).format('Do MMM YY hh:mm a')
})
Vue.filter('time', function (text) {
  return moment(text).format('hh:mm a')
})
Vue.filter('longDate', function (text) {
  return moment(text).format('Do MMMM, YYYY')
})
Vue.filter('longDatePlusDay', function (text) {
  return moment(text).format('ddd, Do MMM, YYYY')
})
Vue.filter('shortDatePlusDay', function (text) {
  return moment(text).format('ddd, Do MMMM')
})

new Vue({
  vuetify,
  apolloProvider,
  router,
  store,
  fileDownload,
  render: h => h(App)
}).$mount('#app')
