<template>
  <v-overlay :value="isLoading">
    <v-progress-circular
      indeterminate
      size="100"
      color="primary"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  data: () => ({
    isLoading: true,
  }),
};
</script>
