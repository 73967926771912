import { apolloClient } from "../../../apollo/apolloVueClient";
import { LIST_TAGS } from "../../../graphql/queries/all_queries";
import {
  CREATE_DEFINITIONS,
  DELETE_DEFINITIONS,
  UPDATE_DEFINITIONS,
} from "../../../graphql/mutations/tags_mutations";

export default {
  // initial state
  namespaced: true,
  state: {
    definitions: [],
    currentPage: null,
    totalPages: null,
  },

  //getters
  getters: {
    allDefinitions: (state) => {
      return state.definitions;
    },
    hasDefinitions: (state) => {
      return state.employees && state.employees.length > 0;
    },
    currentPage: (state) => {
      return state.currentPage || 1;
    },
    totalPages: (state) => {
      return state.totalPages;
    },
  },

  // mutations
  mutations: {
    ADD_DEFINITION(state, payload) {
      let definitions = state.definitions.concat(payload);
      state.definitions = definitions;
    },
    SET_DEFINITION(state, payload) {
      state.definitions = payload;
    },
    EDIT_DEFINITION(state, payload) {
      state.definitions = state.definitions.map((definition) => {
        if (definition.tagId === payload.tagId) {
          return payload;
        }
        return definition;
      });
    },
    DELETE_DEFINITION(state, empID) {
      state.definitions = state.definitions.filter((emp) => {
        return emp.tagId !== empID;
      });
    },
    SET_CURRENT_PAGE(state, payload) {
      state.currentPage = payload + 1;
    },
    SET_TOTAL_PAGE(state, payload) {
      state.totalPages = payload;
    },
  },

  // actions
  actions: {
    async createDefinitions({ commit }, payload) {
      const tagInput = {
        tag: payload.tag,
        tagType: payload.tagType,
      };
      //CREATE TAGS ON SERVER
      try {
        let response = await apolloClient.mutate({
          mutation: CREATE_DEFINITIONS,
          variables: {
            tagInput,
          },
        });
        let saveDefinitions = response.data?.createTag?.data;
        commit("ADD_DEFINITION", saveDefinitions);
        return saveDefinitions;
      } catch (error) {
        console.log(error);
      }
    },

    async fetchDefinitions({ commit }, page) {
      //GET ALL EMPLOYEES FROM SERVER
      try {
        let response = await apolloClient.query({
          query: LIST_TAGS,
          fetchPolicy: "network-only",
          variables: {
            size: 20,
            page: page - 1,
          },
        });
        commit("SET_DEFINITION", response.data?.listTags?.data);
        commit("SET_CURRENT_PAGE", response.data?.listTags?.pageable?.number);
        commit("SET_TOTAL_PAGE", response.data?.listTags?.pageable?.totalPages);
      } catch (error) {
        console.log(error);
      }
    },

    async editDefinitions({ commit }, payload) {
      const tagInput = {
        tagId: payload.tagId,
        tag: payload.tag,
        tagType: payload.tagType,
      };
      //UPDATE TAGS ON SERVER
      try {
        let response = await apolloClient.mutate({
          mutation: UPDATE_DEFINITIONS,
          variables: {
            tagInput,
          },
        });
        let editEmployees = response.data?.updateTag?.data;
        commit("EDIT_DEFINITION", editEmployees);
        return editEmployees;
      } catch (error) {
        console.log(error);
      }
    },
    async deleteDefinitions({ commit }, payload) {
      return new Promise((resolve, reject) => {
        // api.delete(`${endpoint.deleteDefinitionsEndpoints}/${payload}`)
        apolloClient
          .mutate({
            mutation: DELETE_DEFINITIONS,
            variables: {
              id: payload,
            },
          })
          .then(() => {
            commit("DELETE_DEFINITION", payload);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
