import gql from "graphql-tag";

export const GET_TEAM_PERFORMANCE = gql`
  query ListPayrollEmployeesWithTeamPerformance(
    $page: Int
    $size: Int
    $yearMonth: String
    $searchName: String
  ) {
    listPayrollEmployeesWithTeamPerformance(
      page: $page
      size: $size
      yearMonth: $yearMonth
      searchName: $searchName
    ) {
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
      data {
        payrollId
        pid
        month
        year
        basicSalary
        cashEmolument
        assessableIncome
        totalReliefs
        chargeableIncome
        totalTaxPayableToGRA
        employeeTierThreePre
        employerTierThreePre
        hazardous
        optionCode
        remarks
        employeeTierThreeAfter
        employerTierThreeAfter
        accommodationElement
        vehicleElement
        nonCashBenefit
        overtimeIncome
        overtimeTax
        severancePay
        initialGross
        totalAllowance
        totalTaxableAllowance
        totalNonTaxableAllowance
        grossSalary
        employeeSSF
        tierOne
        tierTwo
        taxRelief
        totalRelief
        taxableIncome
        paye
        loanDeduction
        totalDeduction
        netSalary
        employerSSF
        employeeCostToCompany
        bonusTotal
        totalNetBonus
        totalTaxOnBonus
        payableNetSalary
        excessBonus
        initialBonus
        backPay
        date
        studentLoanTrustFund
        employee {
          id
          userId
          customPayrollID
          employeeId
          firstName
          middleName
          lastName
          displayName
          workEmail
          department
          jobTitle
          isManager
          secondaryEmployment
          organization
          employeePosition
          status
          nonResident
          basicSalary
          taxRelief
          backPay
          tierThreePercentage
          employeeType
          profile_image
          employeeBankDetails {
            id
            userId
            bankName
            accountName
            branchName
            accountNumber
            sortCode
            employeeTierThreePercent
          }
          employeeIdentification {
            id
            userId
            customSSN
            customTIN
            ssnitContributor
            ghanaCard
            nonResident
            tierTwo
            tierThree
          }
        }
        teamPerformance {
            id
            sixMonthsSalary
            tpBonus
            excessTpBonus
            tpTax
        }
      }
    }
  }
`;

export const GET_KPI = gql`
  query ListPayrollEmployeesWithKpiBonus(
    $page: Int
    $size: Int
    $yearMonth: String
    $searchName: String
    $startWith: String
  ) {
    listPayrollEmployeesWithKpiBonus(
      page: $page
      size: $size
      yearMonth: $yearMonth
      searchName: $searchName
      startWith: $startWith
    ) {
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
      data {
        payrollId
        pid
        month
        year
        basicSalary
        cashEmolument
        assessableIncome
        totalReliefs
        chargeableIncome
        totalTaxPayableToGRA
        employeeTierThreePre
        employerTierThreePre
        hazardous
        optionCode
        remarks
        employeeTierThreeAfter
        employerTierThreeAfter
        accommodationElement
        vehicleElement
        nonCashBenefit
        overtimeIncome
        overtimeTax
        severancePay
        initialGross
        totalAllowance
        totalTaxableAllowance
        totalNonTaxableAllowance
        grossSalary
        employeeSSF
        tierOne
        tierTwo
        taxRelief
        totalRelief
        taxableIncome
        paye
        loanDeduction
        totalDeduction
        netSalary
        employerSSF
        employeeCostToCompany
        bonusTotal
        totalNetBonus
        totalTaxOnBonus
        payableNetSalary
        excessBonus
        initialBonus
        backPay
        date
        studentLoanTrustFund
        kpiBonus {
          id
          annualSalary
          kpBonus
          excessKpiBonus
          kpiTax
        }
        employee {
          id
          userId
          customPayrollID
          employeeId
          firstName
          middleName
          lastName
          displayName
          workEmail
          department
          jobTitle
          isManager
          secondaryEmployment
          organization
          employeePosition
          status
          nonResident
          basicSalary
          taxRelief
          backPay
          tierThreePercentage
          employeeType
          profile_image
          employeeBankDetails {
            id
            userId
            bankName
            accountName
            branchName
            accountNumber
            sortCode
            employeeTierThreePercent
          }
          employeeIdentification {
            id
            userId
            customSSN
            customTIN
            ssnitContributor
            ghanaCard
            nonResident
            tierTwo
            tierThree
          }
        }
      }
    }
  }
`;
