import EmployeeManagerSvg from "../assets/EmployeeManagerSvg.vue";
import ResourceSvg from "../assets/ResourceSvg.vue";
import ProcurementSvg from "../assets/ProcurementSvg.vue";
import PerformanceSvg from "../assets/PerformanceSvg.vue";
import ClockItSvg from "../assets/ClockITSvg.vue";
import ContractSvg from "../assets/ContractSvg.vue";
import LeaveSvg from "../assets/LeaveSvg.vue";
import FinanceAndControlIcon from "../assets/FinanceAndControlIcon.vue";
import IncidenceManagerSvg from "../assets/IncidenceManagerSvg.vue";
import InvoiceSvg from "../assets/InvoiceSvg.vue";
import MeetingRoomSvg from "../assets/MeetingRoomBookingSvg.vue";
import config from "../../public/config";

export const cardData = [
  {
    name: "Super Admin",
    content: "Permit access to all features",
    permit: false,
    permission: "SUPER_ADMIN",
  },
  {
    name: "Manager",
    content: "Permit access to features related to management",
    permit: false,
    permission: "MANAGER",
  },
  {
    name: "Accountant",
    content: "Permit access to features related to accounting",
    permit: false,
    permission: "ACCOUNTANT",
  },
  {
    name: "Senior Accountant",
    content: "Permit access to features related to senior accounting",
    permit: false,
    permission: "SENIOR_ACCOUNTANT",
  },
];

export const tabs = [
  {
    name: "Basic information",
    content: "CreateAccessLevel",
    route: "/create-access",
  },
  {
    name: "Choose permissions",
    content: "ChoosePermissions",
    route: "/permissions",
  },
  {
    name: "Assign access level",
    content: "AssignAccess",
    route: "/assign-access",
  },
];

export const apps = [
  {
    title: "Employee",
    text: "Employee manager",
    num: 0,
    link: config.employee,
    icon: EmployeeManagerSvg,
    key: "employee management",
    bottom: true,
  },
  {
    title: "Resource...",
    text: "Resource manager",
    num: 1,
    link: config.resource,
    icon: ResourceSvg,
    key: "resource management",
    bottom: true,
  },
  {
    title: "Procure...",
    text: "Procurement manager",
    num: 2,
    link: config.procurement,
    icon: ProcurementSvg,
    key: "procurement manager",
    bottom: true,
  },
  {
    title: "Perform...",
    text: "Performance manager",
    num: 3,
    link: config.performance,
    icon: PerformanceSvg,
    key: "performance management",
    bottom: true,
  },
  {
    title: "Contract...",
    text: "Contract manager",
    num: 4,
    link: config.contract,
    icon: ContractSvg,
    key: "contract management",
    bottom: true,
  },
  {
    title: "Clock-IT",
    text: "Clock-IT",
    num: 5,
    link: config.clockIt,
    icon: ClockItSvg,
    key: "time tracker",
    bottom: true,
  },
  {
    title: "Leave...",
    text: "Leave manager",
    num: 6,
    link: config.leave,
    icon: LeaveSvg,
    key: "leave management",
    bottom: true,
  },
  {
    title: "Finance...",
    text: "Finance and control",
    num: 7,
    link: config.finance,
    icon: FinanceAndControlIcon,
    key: "",
    bottom: true,
  },
  {
    title: "Invoice...",
    text: "Invoice manager",
    num: 8,
    link: config.invoice,
    icon: InvoiceSvg,
    key: "invoice manager",
    bottom: true,
  },
  {
    title: "Incident...",
    text: "Incident manager",
    num: 10,
    link: config.incident,
    icon: IncidenceManagerSvg,
    key: "incidence manager",
    bottom: true,
  },
  {
    title: "Meeting...",
    text: "Meeting room booking",
    num: 11,
    link: config.meeting,
    icon: MeetingRoomSvg,
    key: "meeting booking",
    bottom: true,
  },
];

export const tabsItems = [
  { tab: "Payroll", content: "Payroll" },
  { tab: "Tier 1", content: "Tier1" },
  { tab: "Tier 2", content: "Tier2" },
  { tab: "Tier 3", content: "Tier3" },
  { tab: "GRA", content: "GRA" },
  { tab: "Bank Advice", content: "BankAdvice" },
  { tab: "Bonuses", content: "Bonuses" },
  { tab: "Team Performance", content: "TeamPerformance" },
  { tab: "Journal Voucher", content: "JournalVoucher" },
];

export const tabsNspItems = [
  { tab: "Payroll", content: "Payroll" },
  { tab: "Amortization", content: "NspAmortization" },
  { tab: "Bank Advice", content: "BankAdvice" },
  { tab: "Journal Voucher", content: "JournalVoucher" },
];

export const graPayrollHeader = [
  "Name of Employee",
  "Staff ID",
  "Position",
  "Tin",
  "Basic Salary (GH¢)",
  "Gross Salary (GH¢)",
  "Employee's SSF (GH¢)",
  "Tax Reliefs (GH¢)",
  "Total Relief (GH¢)",
  "Taxable Income (GH¢)",
  "PAYE (GH¢)",
  "Total Tax Deductible & Payable (GH¢)",
];

export const nspHeader = [
  { text: "NO.", value: "no", align: "center", sortable: false },
  { text: "NAME", value: "fullName", align: "center" },
  { text: "NSP ID", value: "employeeId", align: "center" },
  {
    text: "MONTHLY NSP ALLOWANCE (GH₵)",
    value: "monthlyNspAllowance",
    align: "center",
  },
  {
    text: "AMALITECH ALLOWANCE (GH₵)",
    value: "monthlyAmalitechAllowance",
    align: "center",
  },
  {
    text: "TOTAL MONTHLY ALLOWANCE PAYABLE (GH₵)",
    value: "monthlyAllowancePayable",
    align: "center",
  },
  {
    text: "MONTHLY CHARGE @ 20% (GH₵)",
    value: "monthlyCharge",
    align: "center",
  },
  {
    text: "MONTHLY TAX (WHT)",
    value: "monthlyAllowanceTax",
    align: "center",
  },
];

export const commonHeaders = [
  { text: "GROSS SALARY (GH₵)", value: "initialGross" },
  { text: "EMPLOYEE SSF (GH₵)", value: "employeeSSF" },
  { text: "EMPLOYEE TIER 3 (GH₵)", value: "employeeTierThreePre" },
  { text: "TAX RELIEF (GH₵)", value: "taxRelief" },
  { text: "TOTAL RELIEF (GH₵)", value: "totalRelief" },
  { text: "TAXABLE INCOME (GH₵)", value: "taxableIncome" },
  { text: "BONUS TAX (GH₵)", value: "totalTaxOnBonus" },
  { text: "PAYE (GH₵)", value: "paye" },
  { text: "STUDENT LOAN (GH₵)", value: "studentLoanTrustFund" },
  { text: "LOAN DEDUCTION (GH₵)", value: "loanDeduction" },
  { text: "TOTAL DEDUCTION (GH₵)", value: "totalDeduction" },
  { text: "NET SALARY (GH₵)", value: "netSalary" },
  { text: "EMPLOYER SSF (GH₵)", value: "employerSSF" },
  { text: "EMPLOYER TIER 3 (GH₵)", value: "employerTierThreePre" },
  {
    text: "EMPLOYEE COST TO COMPANY (GH₵)",
    value: "employeeCostToCompany",
  },
];

export const tpHeaders = [
  { text: "TEAM PERFORMANCE (GH₵)", value: "teamPerformance" },
  {
    text: "EXCESS TEAM PERFORMANCE (GH₵)",
    value: "excessTeamPerformance",
  },
  ...commonHeaders,
];

export const kpHeaders = [
  { text: "KPI (GH₵)", value: "kpi" },
  {
    text: "EXCESS KPI (GH₵)",
    value: "excessKpi",
  },
  ...commonHeaders,
];

export const tier1PayrollHeaders = [
  { text: "S/N", align: "start", sortable: false, value: "no" },
  { text: "SS Number", value: "customSSN", align: "center" },
  { text: "NIA Number", value: "ghanaCard", align: "center" },
  { text: "SURNAME", value: "lastName", align: "center" },
  { text: "FIRST NAME", value: "firstName", align: "center" },
  { text: "OTHER NAME", value: "middleName", align: "center" },
  {
    text: "OPTION CODE (PNDCL 247/ACT 766)",
    value: "act",
    align: "center",
  },
  { text: "HAZARDOUS (Y/N)", value: "hazardous", align: "center" },
  { text: "SALARY", value: "basicSalary", align: "center" },
  { text: "SSF (13.5)", value: "tierOne", align: "center" },
];

export const tier2PayrollHeaders = [
  { text: "NO.", align: "start", sortable: false, value: "no" },
  { text: "SSNITNo", value: "customSSN", align: "center" },
  { text: "MemberNo", value: "tierTwoNumber", align: "center" },
  { text: "MemberName", value: "fullName", align: "center" },
  {
    text: "BasicSalary",
    value: "basicSalary",
    align: "center",
  },
  { text: "MemberPreTax", value: "tierTwo", align: "center" },
];

export const tier3PayrollHeaders = [
  { text: "NO.", value: "no", align: "start", sortable: false },
  {
    text: "SSNITNo",
    align: "start",
    sortable: false,
    value: "customSSN",
  },
  {
    text: "MemberNo",
    value: "tierTwoNumber",
    align: "center",
    sortable: false,
  },
  { text: "MemberName", value: "displayName", align: "center" },
  { text: "BasicSalary", value: "basicSalary", align: "center" },
  {
    text: "EmployerPreTax",
    value: "employerTierThreePre",
    align: "center",
  },
  {
    text: "EmployerAfterTax",
    value: "employerTierThreeAfter",
    align: "center",
  },
  {
    text: "MemberPreTax",
    value: "employeeTierThreePre",
    align: "center",
  },
  {
    text: "MemberAfterTax",
    value: "employeeTierThreeAfter",
    align: "center",
  },
];

export const employeeSalaryHeaders = [
    {
      text: 'No.',
      align: 'start',
      sortable: false,
      value: 'name',
    },
    { text: 'EMPLOYEE NAME', value: 'employee_name' },
    { text: 'EMPLOYEE ID', value: 'employee_id' },
    { text: 'EMAIL', value: 'email' },
    { text: 'JOB TITLE', value: 'job_title' },
    { text: 'BASIC SALARY', value: 'basic_salary' },
    { text: 'PAY SLIP', value: 'pay_slip' },
    { text: 'ACTION', value: 'action' },
  ];

  export const reportDepartments = [
    { name: "All Departments", abbreviation: "" },
    { name: "Service Center", abbreviation: "SC" },
    { name: "Training Center", abbreviation: "TRN" },
    { name: "Operations", abbreviation: "OP" },
    { name: "Management", abbreviation: "MG" },
];