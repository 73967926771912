import gql from "graphql-tag";

export const CREATE_PENSION = gql`
  mutation CreatePensions($pensionInput: [PensionInput!]!) {
    createPensions(pensionInput: $pensionInput) {
      message
      success
      code
      data {
        id
        label
        percentage
      }
    }
  }
`;
