const apiUrl = process.env.VUE_APP_ERP_API
const apiBaseUrl = process.env.VUE_APP_SSO
const loginUrl = process.env.VUE_APP_MAIN_PAGE_URL
const apiGraphqlBaseUrl = process.env.VUE_APP_API_BASE_URL_GRAPHQL
const contractUrl = process.env.VUE_APP_CONTRACT_URL
const documentsUrl = process.env.VUE_APP_DOCUMENTS_URL
const clockItUrl = process.env.VUE_APP_CLOCK_IT_URL
const employeeUrl = process.env.VUE_APP_EMPLOYEE_URL
const fncUrl = process.env.VUE_APP_FNC_URL
const invoiceUrl = process.env.VUE_APP_INVOICE_URL
const leaveUrl = process.env.VUE_APP_LEAVE_URL
const loansUrl = process.env.VUE_APP_LOANS_URL
const performanceUrl = process.env.VUE_APP_PERFORMANCE_URL
const procurementUrl = process.env.VUE_APP_PROCUREMENT_URL
const projectUrl = process.env.VUE_APP_PROJECT_URL
const financeUrl = process.env.VUE_APP_FNC_URL
const meetingUrl = process.env.VUE_APP_MRBS_URL
const hilfeUrl = process.env.VUE_APP_HILFE_URL
module.exports = {
    api: apiUrl,
    loginUrl: loginUrl,
    apiBaseUrl: apiBaseUrl,
    apiGraphqlBaseUrl: apiGraphqlBaseUrl + '/graphql',
    contract: contractUrl,
    documents: documentsUrl,
    clockIt: clockItUrl,
    employee: employeeUrl,
    fnc: fncUrl,
    invoice: invoiceUrl,
    leave: leaveUrl,
    loans: loansUrl,
    performance: performanceUrl,
    procurement: procurementUrl,
    project: projectUrl,
    finance: financeUrl,
    meeting: meetingUrl,
    incident: hilfeUrl
}