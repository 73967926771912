import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/LoginPage.vue";
import * as authService from "../services/auth";
import * as roles from "../services/roles";
import config from "../../public/config";

Vue.use(VueRouter);

const routes = [
  {
    // ROUTE TO ACCESS LOGIN PAGE
    path: "/",
    name: "login",
    component: Login,
    beforeEnter: (to, from, next) => {
      next();
    },
    meta: {
      layout: "blank",
    },
  },
  {
    // ROUTE TO ACCESS EMPLOYEES PAGE
    path: "/employee",
    name: "employee",
    meta: {
      title: "Employee",
      requiresAuth: true,
    },
    component: () => import("../views/EmployeeSalary.vue"),
  },
  {
    // ROUTE TO ACCESS DEFINITIONS PAGE
    path: "/definitions",
    name: "defintions",
    meta: {
      title: "Definitions",
      requiresAuth: true,
    },
    component: () => import("../views/DefinitionsTab.vue"),
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    // ROUTE TO ACCESS STATUTORY DEDUCTIONS PAGE
    path: "/statutory-deductions",
    name: "statutory-deductions",
    meta: {
      title: "Statutory Deductions",
      requiresAuth: true,
    },
    component: () => import("../views/StatutoryDeductions.vue"),
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: "/access-level",
    name: "access-level",
    meta: {
      title: "Access Level",
      requiresAuth: true,
    },
    component: () => import("../views/AccessLevel/AccessLevel.vue"),
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: "/configure-access",
    name: "configure-access",
    meta: {
      title: "Configure Access",
      requiresAuth: true,
    },
    component: () =>
      import("../components/AccessLevel/ConfigureAccessLevel.vue"),
    children: [
      {
        path: "/permissions",
        component: () =>
          import("../components/AccessLevel/ChoosePermissions.vue"),
      },
      {
        path: "/assign-access",
        component: () => import("../components/AccessLevel/AssignAccess.vue"),
      },
      {
        path: "/create-access",
        component: () =>
          import("../components/AccessLevel/CreateAccessLevel.vue"),
      },
    ],
    beforeEnter: (to, from, next) => {
      next();
    },
  },

  {
    path: "/service-center-payroll",
    name: "service-center-payroll",
    meta: {
      title: "Servce Center Payroll",
      requiresAuth: true,
    },
    component: () => import("../views/ServiceCenter.vue"),
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: "/training-center-payroll",
    name: "training-center-payroll",
    meta: {
      title: "Training Center Payroll",
      requiresAuth: true,
    },
    component: () => import("../views/TrainingCenter.vue"),
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: "/operations-payroll",
    name: "operations-payroll",
    meta: {
      title: "Operations Payroll",
      requiresAuth: true,
    },
    component: () => import("../views/OperationsPage.vue"),
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: "/nsp",
    name: "nsp",
    meta: {
      title: "NSP",
      requiresAuth: true,
    },
    component: () => import("../views/NspPage.vue"),
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: "/management-payroll",
    name: "management-payroll",
    meta: {
      title: "Management Payroll",
      requiresAuth: true,
    },
    component: () => import("../views/ManagementPage.vue"),
    beforeEnter: (to, from, next) => {
      if (roles.isConfidential()) {
        next();
      } else {
        next({ name: "not_authorized" });
      }
    },
  },
  {
    path: "/additions",
    name: "additions",
    meta: {
      title: "Additions",
      requiresAuth: true,
    },
    component: () => import("../views/AdditionsTab.vue"),
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: "/deductions",
    name: "deductions",
    meta: {
      title: "Deductions",
      requiresAuth: true,
    },
    component: () => import("../views/DeductionsTab.vue"),
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: "/payroll-report",
    name: "payroll-report",
    meta: {
      title: "Payroll Report",
      requiresAuth: true,
    },
    component: () => import("../views/reports/PayrollReport.vue"),
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: "/payslip-report",
    name: "payslip-report",
    meta: {
      title: "Payslip Report",
      requiresAuth: true,
    },
    component: () => import("../views/reports/PayslipsReport.vue"),
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: "/nsp-payslip-report",
    name: "nsp-payslip-report",
    meta: {
      title: "NSP Payslip Report",
      requiresAuth: true,
    },
    component: () => import("../views/reports/NspPayslips.vue"),
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: "/bank-advice-report",
    name: "bank-advice-report",
    meta: {
      title: "Bank Advice Report",
      requiresAuth: true,
    },
    component: () => import("../views/reports/BankAdvice.vue"),
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: "/tier-1-report",
    name: "tier-1-report",
    meta: {
      title: "Tier 1 Report",
      requiresAuth: true,
    },
    component: () => import("../views/reports/Tier1Report.vue"),
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: "/tier-2-report",
    name: "tier-2-report",
    meta: {
      title: "Tier 2 Report",
      requiresAuth: true,
    },
    component: () => import("../views/reports/Tier2Report.vue"),
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: "/gra-report",
    name: "gra-report",
    meta: {
      title: "GRA Report",
      requiresAuth: true,
    },
    component: () => import("../views/reports/GraReport.vue"),
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: "/salaryJV",
    name: "salaryJV",
    meta: {
      title: "Salary JV Report",
      requiresAuth: true,
    },
    component: () => import("../views/reports/SalaryJV.vue"),
    beforeEnter: (to, from, next) => {
      next();
    },
  },
  {
    path: "/notfound",
    name: "notfound",
    component: () => import("../components/NotFound.vue"),
    beforeEnter: (to, from, next) => {
      next();
    },
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/not_authorized",
    name: "not_authorized",
    component: () => import("../components/NotAuthorized.vue"),
    beforeEnter: (to, from, next) => {
      next();
    },
    meta: {
      layout: "blank",
    },
  },
  {
    path: "/:catchAll(.*)*",
    redirect: "/notfound",
    beforeEnter: (to, from, next) => {
      next();
    },
    meta: {
      layout: "blank",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,

  // return desired position
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, _from, next) => {
  if (to.meta.requiresAuth && to.query["app-token"]) {
    next();
    // this route requires auth, check if logged in
    // if not, redirect to login page.
  } else if (to.meta.requiresAuth && !authService.isLoggedIn()) {
    const redirect = `${window.location.protocol}//${window.location.host}`;
    window.location.href = `${config.apiBaseUrl}sso-login?redirect-to=${redirect}`;
  } else {
    next(); // make sure to always call next()!
  }
});
export default router;
