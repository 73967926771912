import gql from "graphql-tag";

export const CREATE_ACCESS_LEVEL = gql`
  mutation CreateAccessLevel($accessLevelInput: AccessLevelInput!) {
    createAccessLevel(accessLevelInput: $accessLevelInput) {
      message
      success
      code
      data {
        accessLevel {
          id
          name
          description
          permissions {
            id
            name
          }
        }
        employees {
          id
          userId
          customPayrollID
          employeeId
          firstName
          middleName
          lastName
          displayName
          workEmail
          department
          jobTitle
          isManager
          secondaryEmployment
          organization
          employeePosition
          status
          nonResident
          basicSalary
          taxRelief
          backPay
          tierThreePercentage
          employeeType
          profile_image
          employeeBankDetails {
            id
            userId
            bankName
            accountName
            branchName
            accountNumber
            sortCode
            employeeTierThreePercent
          }
          employeeIdentification {
            id
            userId
            customSSN
            customTIN
            ssnitContributor
            ghanaCard
            nonResident
            tierTwo
            tierThree
          }
        }
      }
    }
  }
`;

export const UPDATE_ACCESS_LEVEL = gql`
  mutation UpdateAccessLevel($id: ID!, $accessLevelInput: AccessLevelInput!) {
    updateAccessLevel(id: $id, accessLevelInput: $accessLevelInput) {
      message
      success
      code
      data {
        accessLevel {
          id
          name
          description
          permissions {
            id
            name
          }
        }
        employees {
          id
          userId
          customPayrollID
          employeeId
          firstName
          middleName
          lastName
          displayName
          workEmail
          department
          jobTitle
          isManager
          secondaryEmployment
          organization
          employeePosition
          status
          nonResident
          basicSalary
          taxRelief
          backPay
          tierThreePercentage
          employeeType
          employeeBankDetails {
            id
            userId
            bankName
            accountName
            branchName
            accountNumber
            sortCode
            employeeTierThreePercent
          }
          employeeIdentification {
            id
            userId
            customSSN
            customTIN
            ssnitContributor
            ghanaCard
            nonResident
            tierTwo
            tierThree
          }
        }
      }
    }
  }
`;

export const ASSIGN_ACCESS_LEVEL = gql`
  mutation AssignAccessLevel(
    $assignAccessLevelInput: [AssignAccessLevelInput!]!
  ) {
    assignAccessLevel(assignAccessLevelInput: $assignAccessLevelInput) {
      message
      success
      code
      data {
        id
        name
        description
        permissions {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_ACCESS_LEVEL = gql`
  mutation DeleteAccessLevel($id: [ID]!) {
    deleteAccessLevel(id: $id) {
      message
      success
      code
    }
  }
`;

export const REVOKE_ACCESS_LEVEL = gql`
mutation RevokeAccessLevel($userIds: [ID]!) {
  revokeAccessLevel(userIds: $userIds) {
      message
      success
      code
  }
}
`;