import gql from "graphql-tag";

export const LIST_PERMISSIONS = gql`
  query ListPermissions($page: Int, $size: Int) {
    listPermissions(page: $page, size: $size) {
      data {
        id
        name
      }
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
    }
  }
`;

export const LIST_ACCESS_LEVELS = gql`
  query ListAccessLevels($page: Int, $size: Int) {
    listAccessLevels(page: $page, size: $size) {
      data {
        id
        name
        description
        permissions {
          id
          name
        }
        employees {
          id
          userId
          customPayrollID
          employeeId
          firstName
          middleName
          lastName
          displayName
          workEmail
          department
          jobTitle
          isManager
          secondaryEmployment
          organization
          employeePosition
          status
          nonResident
          basicSalary
          taxRelief
          backPay
          tierThreePercentage
          employeeType
          profile_image
          employeeBankDetails {
            id
            userId
            bankName
            accountName
            branchName
            accountNumber
            sortCode
            employeeTierThreePercent
          }
          employeeIdentification {
            id
            userId
            customSSN
            customTIN
            ssnitContributor
            ghanaCard
            nonResident
            tierTwo
            tierThree
          }
        }
      }
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
    }
  }
`;
