import { apolloClient } from "../../../apollo/apolloVueClient";
import {
  LIST_ACCESS_LEVELS,
  LIST_PERMISSIONS,
} from "../../../graphql/queries/access_level_queries";
import {
  CREATE_ACCESS_LEVEL,
  UPDATE_ACCESS_LEVEL,
  ASSIGN_ACCESS_LEVEL,
  DELETE_ACCESS_LEVEL,
  REVOKE_ACCESS_LEVEL,
} from "../../../graphql/mutations/access_level_mutations";

export default {
  namespaced: true,
  state: {
    formData: {
      name: "",
      description: "",
      permissions: [],
      userIds: [],
    },
    updateForm: {
      name: "",
      description: "",
      permissions: [],
      userIds: [],
    },
    status: false,
    permissions: [],
    accessLevels: [],
    selectedOptions: [],
    currentPage: null,
    totalPages: null,
  },

  getters: {
    formData: (state) => state.formData,
    updateForm: (state) => state.updateForm,
    permissions: (state) => state.permissions,
    userIds: (state) => state.formData.userIds,
    currentPage: (state) => state.currentPage || 1,
    totalPages: (state) => state.totalPages,
    status: (state) => state.status,
    updateIds: (state) => state.updateForm.userIds,
    accessLevels: (state) => state.accessLevels,
    selectedOptions: (state) => state.selectedOptions,
  },

  mutations: {
    SET_FORM_DATA(state, newFormData) {
      state.formData = newFormData;
    },
    SET_FORM_NAME(state, newName){
      state.formData.name = newName;
    },
    SET_FORM_DESCRIPTION(state, newVal){
      state.formData.description = newVal;
    },
    UPDATE_FORM_DATA(state, payload) {
      state.updateForm = { ...state.updateForm, ...payload };
    },
    SET_USER_IDS(state, payload) {
      state.formData.userIds = payload;
    },
    UPDATE_USER_IDS(state, payload) {
      state.updateForm.userIds = payload;
    },
    SET_STATUS(state, payload) {
      state.status = payload;
    },
    SET_CURRENT_PAGE(state, payload) {
      state.currentPage = payload;
    },
    SET_TOTAL_PAGE(state, payload) {
      state.totalPages = payload;
    },
    LIST_PERMISSIONS(state, payload) {
      state.permissions = payload;
    },
    SET_PERMISSIONS(state, payload) {
      state.formData.permissions = payload;
    },
    UPDATE_PERMISSIONS(state, payload) {
      state.updateForm.permissions = payload;
    },
    LIST_ACCESS_LEVEL(state, payload) {
      state.accessLevels = payload;
    },
    CREATE_ACCESS_LEVEL(state, payload) {
      state.accessLevels = state.accessLevels.concat(payload);
    },
    DELETE_ACCESS_LEVEL(state, userId) {
      state.accessLevels = state.accessLevels.filter(
        (level) => !userId.includes(level.id)
      );
    },
    UPDATE_SELECTED_OPTIONS(state, payload) {
      state.selectedOptions = payload;
    },
  },

  actions: {
    async assignAccessLevel({ state, commit }) {
      try {
        const response = await apolloClient.mutate({
          mutation: ASSIGN_ACCESS_LEVEL,
          variables: {
            assignAccessLevelInput: state.selectedOptions,
          },
        });
        const queryData = response?.data?.assignAccessLevel;
        commit("ASSIGN_ACCESS_LEVEL", queryData);
      } catch (error) {
        console.log(error);
      }
    },

    async createAccessLevel({ state, commit, dispatch }) {
      commit("SET_STATUS", true);
      try {
        const response = await apolloClient.mutate({
          mutation: CREATE_ACCESS_LEVEL,
          variables: {
            accessLevelInput: state.formData,
          },
        });
        const queryData = response?.data?.createAccessLevel;
        commit("SET_FORM_DATA", state.formData);
        if (queryData.success) {
          dispatch("listAccessLevel", { page: 1});
          Fire.$emit(
            "create-success",
            `Access level has been created successfully`
          );
          commit("SET_STATUS", false);
        }
      } catch (error) {
        Fire.$emit("create-error", `Failed to create access level`);
        commit("SET_STATUS", false);
        console.log(error);
      }
    },

    async listAccessLevel({ commit }, payload) {
      try {
        const response = await apolloClient.query({
          query: LIST_ACCESS_LEVELS,
          fetchPolicy: "no-cache",
          variables: {
            page: payload.page - 1,
            size: 10,
          },
        });
        const queryData = response?.data?.listAccessLevels;
        commit("LIST_ACCESS_LEVEL", queryData.data);
        commit("SET_CURRENT_PAGE", queryData.pageable.number + 1);
        commit("SET_TOTAL_PAGE", queryData.pageable.totalPages);
      } catch (error) {
        console.log(error);
      }
    },

    async deleteAccessLevel({ commit }, id) {
      commit("SET_STATUS", true);
      try {
        const response = await apolloClient.mutate({
          mutation: DELETE_ACCESS_LEVEL,
          fetchPolicy: "network-only",
          variables: {
            id: [id],
          },
        });
        const queryData = response.data.deleteAccessLevel;
        commit("DELETE_ACCESS_LEVEL", [id]);
        if (queryData.success) {
          Fire.$emit(
            "delete-success",
            `Access level has been deleted successfully`
          );
          commit("SET_STATUS", false);
        }
      } catch (error) {
        Fire.$emit("delete-error", `Failed to delete access level`);
        commit("SET_STATUS", false);
        console.log(error);
      }
    },

    async updateAccessLevel(context, payload) {
      context.commit("SET_STATUS", true);
      try {
        const response = await apolloClient.mutate({
          mutation: UPDATE_ACCESS_LEVEL,
          fetchPolicy: "no-cache",
          variables: {
            id: payload.id,
            accessLevelInput: payload.accessLevelInput,
          },
        });
        const queryData = response.data.updateAccessLevel;
        if (queryData.success) {
          context.dispatch("listAccessLevel", { page: 1});
          Fire.$emit(
            "update-success",
            `Access level has been updated successfully`
          );
          context.commit("SET_STATUS", false);
        }
      } catch (error) {
        Fire.$emit("update-error", `Failed to update access level`);
        context.commit("SET_STATUS", false);
        console.log(error);
      }
    },

    async revokeAccessLevel({ commit, dispatch }, id) {
      commit("SET_STATUS", true);
      try {
        const response = await apolloClient.mutate({
          mutation: REVOKE_ACCESS_LEVEL,
          fetchPolicy: "no-cache",
          variables: {
            userIds: [id],
          }
        });
        const queryData = response.data.revokeAccessLevel;
        dispatch("listAccessLevel", { page: 1});
        if(queryData.success) {
          Fire.$emit(
            "revoke-success",
            `Access level has been revoked successfully`
          );
          commit("SET_STATUS", false);
        }
      }catch(error) {
        Fire.$emit("revoke-error", `Failed to revoke access level`);
        commit("SET_STATUS", false);
        console.log(error);
      }
    },

    async listPermissions({ commit }) {
      try {
        const response = await apolloClient.query({
          query: LIST_PERMISSIONS,
          fetchPolicy: "cache-first",
          variables: {
            page: 0,
            size: 25,
          },
        });

        const queryData = response?.data?.listPermissions.data.map((perm) => {
          const { __typename, ...newObj } = perm;
          if (__typename === "Permission" && newObj.name !== "SUPER_ADMIN") {
            return newObj.name;
          }
          return newObj.name;
        });
        commit("LIST_PERMISSIONS", queryData);
      } catch (error) {
        console.log(error);
      }
    },

    async updatePermissions({ commit }, payload) {
      await commit("UPDATE_PERMISSIONS", payload);
    },
  },
};
