<template>
  <v-snackbar
    :timeout="5000"
    :value="true"
    v-model="snackbar"
    absolute
    top
    center
    text
    :color="msgError ? 'error' : 'success'"
  >
    {{ textMsg }}

  </v-snackbar>
</template>

<script>
export default {
  name: 'Snackbar',
   props: {
    snackbar: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: "success",
    },
    timeout: {
      type: Number,
      required: false,
      default: 3000,
    },
    text: {
      type: String,
      required: true,
    },
  },
  data: ()=>({
    snackbar: false,
  })
};
</script>
