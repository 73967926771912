import gql from "graphql-tag";
export const BONUSES_BY_EMPLOYEE_ID = gql`
  query ListAllByEmployeeId($employeeIdInput: MonthlyBonusByEmployeeIdInput) {
    listAllByEmployeeId(employeeIdInput: $employeeIdInput) {
      id
      employeeId
      amount
      month
      description
    }
  }
`;

export const BONUSES_BY_MONTH_EMPLOYEE_ID = gql`
  query listAllByMonthAndEmployeeId($monthlyBonusInput: MonthlyBonusInput) {
    listAllByMonthAndEmployeeId(monthlyBonusInput: $monthlyBonusInput) {
      id
      employeeId
      amount
      month
      description
    }
  }
`;

export const ALL_PENSIONS = gql`
  query ListPensions {
    listPensions {
      id
      label
      percentage
    }
  }
`;

export const LIST_TAXES = gql`
  query ListPayeConfigs {
    listPayeConfigs {
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
      data {
        id
        label
        percentage
        amount
      }
    }
  }
`;

export const SEARCH_FOR_ALLOWANCE = gql`
  query ListAllowanceByDisplayName(
    $searchInput: String!
    $size: Int
    $page: Int
  ) {
    listAllowanceByDisplayName(
      searchByDisplayNameInput: $searchInput
      size: $size
      page: $page
    ) {
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
      data {
        id
        taxable
        employeeId
        employeeNumber
        description
        startDate
        endDate
        totalAllowance
        monthlyAllowance
        displayName
        date
      }
    }
  }
`;

export const LIST_BONUSES = gql`
  query ListBonuses($size: Int, $page: Int) {
    listBonuses(size: $size, page: $page) {
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
      data {
        id
        employeeId
        employeeNumber
        description
        startDate
        endDate
        totalBonus
        monthlyBonus
        displayName
        date
      }
    }
  }
`;

export const EMPLOYEES_WITH_BONUS_DEPT = gql`
  query ListEmployeesWithBonusForCurrentMonthWithDepartment {
    listEmployeesWithBonusForCurrentMonthWithDepartment(startWithInput: null) {
      id
      employeeId
      employeeNumber
      description
      startDate
      endDate
      totalBonus
      monthlyBonus
      displayName
      date
      payroll {
        payrollId
        pid
        month
        year
        cashEmolument
        assessableIncome
        totalReliefs
        chargeableIncome
        totalTaxPayableToGRA
        employeeTierThreePre
        employerTierThreePre
        hazardous
        optionCode
        remarks
        employeeTierThreeAfter
        employerTierThreeAfter
        accommodationElement
        vehicleElement
        nonCashBenefit
        overtimeIncome
        overtimeTax
        severancePay
        initialGross
        totalAllowance
        totalTaxableAllowance
        totalNonTaxableAllowance
        grossSalary
        employeeSSF
        tierOne
        tierTwo
        taxRelief
        totalRelief
        taxableIncome
        paye
        loanDeduction
        totalDeduction
        netSalary
        employerSSF
        employeeCostToCompany
        bonusTotal
        totalNetBonus
        totalTaxOnBonus
        payableNetSalary
        excessBonus
        initialBonus
        backPay
        date
        studentLoanTrustFund
        bonuses {
          id
          employeeId
          employeeNumber
          description
          startDate
          endDate
          totalBonus
          monthlyBonus
          displayName
          date
        }
        deductions {
          id
          employeeId
          employeeNumber
          description
          startDate
          endDate
          amountDue
          monthlyDeduction
          displayName
          totalDeduction
          date
        }
        allowances {
          id
          taxable
          employeeId
          employeeNumber
          description
          startDate
          endDate
          totalAllowance
          monthlyAllowance
          displayName
          date
        }
        employee {
          id
          userId
          customPayrollID
          employeeId
          firstName
          middleName
          lastName
          displayName
          workEmail
          department
          jobTitle
          isManager
          secondaryEmployment
          organization
          employeePosition
          status
          nonResident
          basicSalary
          taxRelief
          backPay
          tierThreePercentage
        }
      }
    }
  }
`;

export const EMPLOYEES_WITH_BONUS_MONTH_DEPT = gql`
  query ListEmployeesWithBonusForCurrentMonthWithDepartment(
    $startWithInput: String
  ) {
    listEmployeesWithBonusForCurrentMonthWithDepartment(
      startWithInput: $startWithInput
    ) {
      bonusTotal
      firstName
      lastName
      totalNetBonus
      totalTaxOnBonus
      displayName
      employeeId
      middleName
      employeeNumber
    }
  }
`;

export const SEARCH_BONUSES = gql`
  query ListBonusByDisplayName($searchInput: String!, $page: Int, $size: Int) {
    listBonusByDisplayName(
      searchByDisplayNameInput: $searchInput
      page: $page
      size: $size
    ) {
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
      data {
        id
        employeeId
        employeeNumber
        description
        startDate
        endDate
        totalBonus
        monthlyBonus
        displayName
        date
      }
    }
  }
`;

export const LIST_TAGS = gql`
  query ListTags($size: Int, $page: Int) {
    listTags(size: $size, page: $page) {
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
      data {
        tagId
        tag
        tagType
      }
    }
  }
`;

export const LIST_SSF_LABELS = gql`
  query ListAllSSFLabels {
    listAllSSFLabels {
      employeeSSFRate
      employerSSFRate
      tier_1_rate
      tier_2_rate
    }
  }
`;

export const LIST_SORTED_TAG = gql`
  query ListSortedTag($tagType: String) {
    listSortedTag(tagType: $tagType) {
      tagId
      tag
      tagType
    }
  }
`;

export const LIST_DEDUCTIONS = gql`
  query ListDeductions($size: Int, $page: Int) {
    listDeductions(size: $size, page: $page) {
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
      data {
        id
        employeeId
        employeeNumber
        description
        startDate
        endDate
        amountDue
        monthlyDeduction
        displayName
        totalDeduction
        date
      }
    }
  }
`;

export const LIST_DEDUCTIONS_BY_NAME = gql`
  query ListDeductionsByName(
    $searchByDisplayNameInput: String!
    $size: Int
    $page: Int
  ) {
    listDeductionsByName(
      searchByDisplayNameInput: $searchByDisplayNameInput
      size: $size
      page: $page
    ) {
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
      data {
        id
        employeeId
        employeeNumber
        description
        startDate
        endDate
        amountDue
        monthlyDeduction
        displayName
        totalDeduction
        date
      }
    }
  }
`;

export const LIST_ALLOWANCES = gql`
  query ListAllowances($page: Int, $size: Int) {
    listAllowances(page: $page, size: $size) {
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
      data {
        id
        taxable
        employeeId
        employeeNumber
        description
        startDate
        endDate
        totalAllowance
        monthlyAllowance
        displayName
        date
      }
    }
  }
`;

export const LIST_EMPLOYEES = gql`
  query ListEmployees($page: Int, $size: Int) {
    listEmployees(page: $page, size: $size) {
      data {
        id
        userId
        customPayrollID
        employeeId
        firstName
        middleName
        lastName
        displayName
        workEmail
        department
        jobTitle
        isManager
        secondaryEmployment
        organization
        employeePosition
        status
        nonResident
        basicSalary
        taxRelief
        backPay
        tierThreePercentage
        employeeType
        employeeBankDetails {
          id
          userId
          bankName
          accountName
          branchName
          accountNumber
          sortCode
          employeeTierThreePercent
        }
        employeeIdentification {
          id
          userId
          customSSN
          customTIN
          ssnitContributor
          ghanaCard
          nonResident
          tierTwo
          tierThree
        }
      }
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
    }
  }
`;

export const LIST_ACTIVE_EMPLOYEES = gql`
  query ListActiveEmployees($page: Int, $size: Int) {
    listActiveEmployees(page: $page, size: $size) {
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
      data {
        id
        userId
        customPayrollID
        employeeId
        firstName
        middleName
        lastName
        displayName
        workEmail
        department
        jobTitle
        isManager
        secondaryEmployment
        organization
        employeePosition
        status
        nonResident
        basicSalary
        taxRelief
        backPay
        tierThreePercentage
        employeeType
        employeeBankDetails {
          id
          userId
          bankName
          accountName
          branchName
          accountNumber
          sortCode
          employeeTierThreePercent
        }
        employeeIdentification {
          id
          userId
          customSSN
          customTIN
          ssnitContributor
          ghanaCard
          nonResident
          tierTwo
          tierThree
        }
      }
    }
  }
`;

export const LIST_EMPLOYEES_BY_ID_NAME = gql`
  query ListEmployeesWithIdAndNamesOnly {
    listEmployeesWithIdAndNamesOnly {
      id
      userId
      customPayrollID
      employeeId
      firstName
      middleName
      lastName
      displayName
      workEmail
      department
      jobTitle
      isManager
      secondaryEmployment
      organization
      employeePosition
      status
      nonResident
      taxRelief
      backPay
      tierThreePercentage
      employeeType
    }
  }
`;

export const LIST_EMPLOYEES_BY_NAME = gql`
  query ListEmployeesByName($searchName: String!, $page: Int, $size: Int) {
    listEmployeesByName(searchName: $searchName, page: $page, size: $size) {
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
      data {
        id
        userId
        customPayrollID
        employeeId
        firstName
        middleName
        lastName
        displayName
        workEmail
        department
        jobTitle
        isManager
        secondaryEmployment
        organization
        employeePosition
        status
        nonResident
        basicSalary
        taxRelief
        backPay
        tierThreePercentage
        employeeType
        employeeBankDetails {
          id
          userId
          bankName
          accountName
          branchName
          accountNumber
          sortCode
          employeeTierThreePercent
        }
        employeeIdentification {
          id
          userId
          customSSN
          customTIN
          ssnitContributor
          ghanaCard
          nonResident
          tierTwo
          tierThree
        }
      }
    }
  }
`;

export const LIST_CURRENT_PAYROLLS = gql`
  query ListCurrentPayrolls($page: Int, $size: Int) {
    listCurrentPayrolls(page: $page, size: $size) {
      data {
        payrollId
        pid
        month
        year
        cashEmolument
        assessableIncome
        totalReliefs
        chargeableIncome
        totalTaxPayableToGRA
        employeeTierThreePre
        employerTierThreePre
        hazardous
        optionCode
        remarks
        employeeTierThreeAfter
        employerTierThreeAfter
        accommodationElement
        vehicleElement
        nonCashBenefit
        overtimeIncome
        overtimeTax
        severancePay
        initialGross
        totalAllowance
        totalTaxableAllowance
        totalNonTaxableAllowance
        grossSalary
        employeeSSF
        tierOne
        tierTwo
        taxRelief
        totalRelief
        taxableIncome
        paye
        loanDeduction
        totalDeduction
        netSalary
        employerSSF
        employeeCostToCompany
        bonusTotal
        totalNetBonus
        totalTaxOnBonus
        payableNetSalary
        excessBonus
        initialBonus
        backPay
        date
        studentLoanTrustFund
        employee {
          id
          userId
          customPayrollID
          employeeId
          firstName
          middleName
          lastName
          displayName
          workEmail
          department
          jobTitle
          isManager
          secondaryEmployment
          organization
          employeePosition
          status
          nonResident
          basicSalary
          taxRelief
          backPay
          tierThreePercentage
        }
      }
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
    }
  }
`;

export const LIST_CURRENT_PAYROLLS_BY_DEPT = gql`
  query ListCurrentPayrollsByDepartment(
    $startWith: String
    $page: Int
    $size: Int
  ) {
    listCurrentPayrollsByDepartment(
      startWith: $startWith
      page: $page
      size: $size
    ) {
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
      data {
        payrollId
        pid
        month
        year
        cashEmolument
        assessableIncome
        totalReliefs
        chargeableIncome
        totalTaxPayableToGRA
        employeeTierThreePre
        employerTierThreePre
        hazardous
        optionCode
        remarks
        employeeTierThreeAfter
        employerTierThreeAfter
        accommodationElement
        vehicleElement
        nonCashBenefit
        overtimeIncome
        overtimeTax
        severancePay
        initialGross
        totalAllowance
        totalTaxableAllowance
        totalNonTaxableAllowance
        grossSalary
        employeeSSF
        tierOne
        tierTwo
        taxRelief
        totalRelief
        taxableIncome
        paye
        loanDeduction
        totalDeduction
        netSalary
        employerSSF
        employeeCostToCompany
        bonusTotal
        totalNetBonus
        totalTaxOnBonus
        payableNetSalary
        excessBonus
        initialBonus
        backPay
        date
        studentLoanTrustFund
        employee {
          id
          userId
          customPayrollID
          employeeId
          firstName
          middleName
          lastName
          displayName
          workEmail
          department
          jobTitle
          isManager
          secondaryEmployment
          organization
          employeePosition
          status
          nonResident
          basicSalary
          taxRelief
          backPay
          tierThreePercentage
          employeeType
          employeeBankDetails {
            id
            userId
            bankName
            accountName
            branchName
            accountNumber
            sortCode
            employeeTierThreePercent
          }
          employeeIdentification {
            id
            userId
            customSSN
            customTIN
            ssnitContributor
            ghanaCard
            nonResident
            tierTwo
            tierThree
          }
        }
      }
    }
  }
`;

export const LIST_CURRENT_NSP_PAYROLLS = gql`
  query ListCurrentNspPayroll(
    $page: Int
    $size: Int
    $searchName: String
    $yearMonth: String
  ) {
    listCurrentNspPayroll(
      page: $page
      size: $size
      searchName: $searchName
      yearMonth: $yearMonth
    ) {
      data {
        payrollId
        pid
        month
        year
        monthlyNspAllowance
        monthlyAmalitechAllowance
        monthlyAllowancePayable
        monthlyCharge
        yearlyNspAllowance
        yearlyNspAllowanceCharge
        monthlyAllowanceTax
        allowances {
          id
          taxable
          employeeId
          employeeNumber
          description
          startDate
          endDate
          totalAllowance
          monthlyAllowance
          displayName
          date
        }
        employee {
          id
          userId
          customPayrollID
          employeeId
          firstName
          middleName
          lastName
          displayName
          workEmail
          department
          jobTitle
          isManager
          secondaryEmployment
          organization
          employeePosition
          status
          nonResident
          basicSalary
          taxRelief
          backPay
          tierThreePercentage
          employeeType
          employeeBankDetails {
            id
            userId
            bankName
            accountName
            branchName
            accountNumber
            sortCode
            employeeTierThreePercent
          }
          employeeIdentification {
            id
            userId
            customSSN
            customTIN
            ssnitContributor
            ghanaCard
            nonResident
            tierTwo
            tierThree
          }
        }
      }
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
    }
  }
`;

export const CURRENT_PAYROLL_STATUS = gql`
  query ListCurrentPayrollStatus($yearMonth: String) {
    listCurrentPayrollStatus(yearMonth: $yearMonth) {
      psid
      authorized
      approved
      generated
      authorizedBy
      approvedBy
      generatedBy
      generatedDate
      authorizedDate
      approvedDate
      date
    }
  }
`;

export const SEARCH_BY_NAME_AND_DATE = gql`
  query ExportEmployeesPayrollByNameAndDate(
    $yearMonth: YearMonthInput
    $searchName: String
    $page: Int
    $size: Int
  ) {
    exportEmployeesPayrollByNameAndDate(
      yearMonth: $yearMonth
      searchName: $searchName
      page: $page
      size: $size
    ) {
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
      data {
        payrollId
        pid
        month
        year
        cashEmolument
        assessableIncome
        totalReliefs
        chargeableIncome
        totalTaxPayableToGRA
        employeeTierThreePre
        employerTierThreePre
        hazardous
        optionCode
        remarks
        employeeTierThreeAfter
        employerTierThreeAfter
        accommodationElement
        vehicleElement
        nonCashBenefit
        overtimeIncome
        overtimeTax
        severancePay
        initialGross
        totalAllowance
        totalTaxableAllowance
        totalNonTaxableAllowance
        grossSalary
        employeeSSF
        tierOne
        tierTwo
        taxRelief
        totalRelief
        taxableIncome
        paye
        loanDeduction
        totalDeduction
        netSalary
        employerSSF
        employeeCostToCompany
        bonusTotal
        totalNetBonus
        totalTaxOnBonus
        payableNetSalary
        excessBonus
        initialBonus
        backPay
        date
        studentLoanTrustFund
      }
    }
  }
`;

export const EXPORT_PAYROLL_BY_DATE = gql`
  query ExportPayrollByDate(
    $page: Int
    $size: Int
    $searchName: String
    $yearMonth: String
    $startWith: String
  ) {
    exportPayrollByDate(
      page: $page
      size: $size
      searchName: $searchName
      yearMonth: $yearMonth
      startWith: $startWith
    ) {
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
      data {
        payrollId
        pid
        month
        year
        cashEmolument
        assessableIncome
        totalReliefs
        chargeableIncome
        totalTaxPayableToGRA
        employeeTierThreePre
        employerTierThreePre
        hazardous
        optionCode
        remarks
        employeeTierThreeAfter
        employerTierThreeAfter
        accommodationElement
        vehicleElement
        nonCashBenefit
        overtimeIncome
        overtimeTax
        severancePay
        initialGross
        totalAllowance
        totalTaxableAllowance
        totalNonTaxableAllowance
        grossSalary
        employeeSSF
        tierOne
        tierTwo
        taxRelief
        totalRelief
        taxableIncome
        paye
        loanDeduction
        totalDeduction
        netSalary
        employerSSF
        employeeCostToCompany
        bonusTotal
        totalNetBonus
        totalTaxOnBonus
        payableNetSalary
        excessBonus
        initialBonus
        backPay
        date
        studentLoanTrustFund
        employee {
          id
          userId
          customPayrollID
          employeeId
          firstName
          middleName
          lastName
          displayName
          workEmail
          department
          jobTitle
          isManager
          secondaryEmployment
          organization
          employeePosition
          status
          nonResident
          basicSalary
          taxRelief
          backPay
          tierThreePercentage
        }
      }
    }
  }
`;

export const SEARCH_BY_EMPLOYEE_NAME = gql`
  query ListByEmployeeName(
    $searchName: String
    $startWith: String
    $page: Int
    $size: Int
    $yearMonth: String
  ) {
    listByEmployeeName(
      searchName: $searchName
      page: $page
      size: $size
      startWith: $startWith
      yearMonth: $yearMonth
    ) {
      data {
        payrollId
        pid
        month
        year
        cashEmolument
        assessableIncome
        totalReliefs
        chargeableIncome
        totalTaxPayableToGRA
        employeeTierThreePre
        employerTierThreePre
        hazardous
        optionCode
        remarks
        employeeTierThreeAfter
        employerTierThreeAfter
        accommodationElement
        vehicleElement
        nonCashBenefit
        overtimeIncome
        overtimeTax
        severancePay
        initialGross
        totalAllowance
        totalTaxableAllowance
        totalNonTaxableAllowance
        grossSalary
        employeeSSF
        tierOne
        tierTwo
        taxRelief
        totalRelief
        taxableIncome
        paye
        loanDeduction
        totalDeduction
        netSalary
        employerSSF
        employeeCostToCompany
        bonusTotal
        totalNetBonus
        totalTaxOnBonus
        payableNetSalary
        excessBonus
        initialBonus
        backPay
        date
        studentLoanTrustFund
        teamPerformance {
          id
          sixMonthsSalary
          tpBonus
          excessTpBonus
          tpTax
        }
        kpiBonus {
          id
          annualSalary
          kpBonus
          excessKpiBonus
          kpiTax
        }
        bonuses {
          id
          employeeId
          employeeNumber
          description
          startDate
          endDate
          totalBonus
          monthlyBonus
          displayName
          date
        }
        deductions {
          id
          employeeId
          employeeNumber
          description
          startDate
          endDate
          amountDue
          monthlyDeduction
          displayName
          totalDeduction
          date
        }
        allowances {
          id
          taxable
          employeeId
          employeeNumber
          description
          startDate
          endDate
          totalAllowance
          monthlyAllowance
          displayName
          date
        }
        employee {
          id
          userId
          customPayrollID
          employeeId
          firstName
          middleName
          lastName
          displayName
          workEmail
          department
          jobTitle
          isManager
          secondaryEmployment
          organization
          employeePosition
          status
          nonResident
          basicSalary
          taxRelief
          backPay
          tierThreePercentage
          employeeType
          employeeBankDetails {
            id
            userId
            bankName
            accountName
            branchName
            accountNumber
            sortCode
            employeeTierThreePercent
          }
          employeeIdentification {
            id
            userId
            customSSN
            customTIN
            ssnitContributor
            ghanaCard
            nonResident
            tierTwo
            tierThree
          }
        }
      }
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
    }
  }
`;

export const LIST_CURRENT_TIER_THREE_DEPT = gql`
  query ListCurrentTierThreeForDepartment(
    $page: Int
    $size: Int
    $startWith: String
  ) {
    listCurrentTierThreeForDepartment(
      page: $page
      size: $size
      startWith: $startWith
    ) {
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
      data {
        payrollId
        pid
        month
        year
        cashEmolument
        assessableIncome
        totalReliefs
        chargeableIncome
        totalTaxPayableToGRA
        employeeTierThreePre
        employerTierThreePre
        hazardous
        optionCode
        remarks
        employeeTierThreeAfter
        employerTierThreeAfter
        accommodationElement
        vehicleElement
        nonCashBenefit
        overtimeIncome
        overtimeTax
        severancePay
        initialGross
        totalAllowance
        totalTaxableAllowance
        totalNonTaxableAllowance
        grossSalary
        employeeSSF
        tierOne
        tierTwo
        taxRelief
        totalRelief
        taxableIncome
        paye
        loanDeduction
        totalDeduction
        netSalary
        employerSSF
        employeeCostToCompany
        bonusTotal
        totalNetBonus
        totalTaxOnBonus
        payableNetSalary
        excessBonus
        initialBonus
        backPay
        date
        studentLoanTrustFund
        employee {
          id
          userId
          customPayrollID
          employeeId
          firstName
          middleName
          lastName
          displayName
          workEmail
          department
          jobTitle
          isManager
          secondaryEmployment
          organization
          employeePosition
          status
          nonResident
          basicSalary
          taxRelief
          backPay
          tierThreePercentage
        }
      }
    }
  }
`;

export const EXPORT_PAYROLL_BY_DATE_AND_NAME = gql`
  query ExportPayrollByDate(
    $page: Int
    $size: Int
    $yearMonth: String
    $searchName: String
    $startWith: String
  ) {
    exportPayrollByDate(
      page: $page
      size: $size
      yearMonth: $yearMonth
      searchName: $searchName
      startWith: $startWith
    ) {
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
      data {
        payrollId
        pid
        month
        year
        cashEmolument
        assessableIncome
        totalReliefs
        chargeableIncome
        totalTaxPayableToGRA
        employeeTierThreePre
        employerTierThreePre
        hazardous
        optionCode
        remarks
        employeeTierThreeAfter
        employerTierThreeAfter
        accommodationElement
        vehicleElement
        nonCashBenefit
        overtimeIncome
        overtimeTax
        severancePay
        initialGross
        totalAllowance
        totalTaxableAllowance
        totalNonTaxableAllowance
        grossSalary
        employeeSSF
        tierOne
        tierTwo
        taxRelief
        totalRelief
        taxableIncome
        paye
        loanDeduction
        totalDeduction
        netSalary
        employerSSF
        employeeCostToCompany
        bonusTotal
        totalNetBonus
        totalTaxOnBonus
        payableNetSalary
        excessBonus
        initialBonus
        backPay
        date
        studentLoanTrustFund
      }
    }
  }
`;

export const EXPORT_PAYROLL_BY_DEPARTMENT = gql`
  query ExportPayrollByDepartment($startWith: String) {
    exportPayrollByDepartment(startWith: $startWith) {
      employee {
        displayName
        employeeId
        jobTitle
        isManager
        basicSalary
      }
      totalAllowance
      initialBonus
      bonusTotal
      excessBonus
      teamPerformance {
        tpBonus
        excessTpBonus
      }
      backPay
      initialGross
      employeeSSF
      employeeTierThreePre
      taxRelief
      totalRelief
      taxableIncome
      totalTaxOnBonus
      paye
      studentLoanTrustFund
      loanDeduction
      totalDeduction
      netSalary
      employerSSF
      employerTierThreePre
      employeeCostToCompany
    }
  }
`;

export const EXPORT_PAYROLL_BY_NSP = gql`
  query ExportPayrollForNSP($yearMonth: String) {
    exportPayrollForNSP(yearMonth: $yearMonth) {
      payrollId
      pid
      month
      year
      monthlyNspAllowance
      monthlyAmalitechAllowance
      monthlyAllowancePayable
      monthlyCharge
      yearlyNspAllowance
      yearlyNspAllowanceCharge
      monthlyAllowanceTax
      employee {
        employeeId
        displayName
      }
    }
  }
`;

export const AMALITECH_GHANA_REPORT = gql`
  query ListAmalitechGhanaPayroll(
    $page: Int
    $size: Int
    $searchName: String
    $yearMonth: String
  ) {
    listAmalitechGhanaPayroll(
      page: $page
      size: $size
      searchName: $searchName
      yearMonth: $yearMonth
    ) {
      data {
        payrollId
        pid
        month
        year
        basicSalary
        cashEmolument
        assessableIncome
        totalReliefs
        chargeableIncome
        totalTaxPayableToGRA
        employeeTierThreePre
        employerTierThreePre
        hazardous
        optionCode
        remarks
        employeeTierThreeAfter
        employerTierThreeAfter
        accommodationElement
        vehicleElement
        nonCashBenefit
        overtimeIncome
        overtimeTax
        severancePay
        initialGross
        totalAllowance
        totalTaxableAllowance
        totalNonTaxableAllowance
        grossSalary
        employeeSSF
        tierOne
        tierTwo
        taxRelief
        totalRelief
        taxableIncome
        paye
        loanDeduction
        totalDeduction
        netSalary
        employerSSF
        employeeCostToCompany
        bonusTotal
        totalNetBonus
        totalTaxOnBonus
        payableNetSalary
        excessBonus
        initialBonus
        backPay
        date
        studentLoanTrustFund
        employee {
          id
          userId
          customPayrollID
          employeeId
          firstName
          middleName
          lastName
          displayName
          workEmail
          department
          jobTitle
          isManager
          secondaryEmployment
          organization
          employeePosition
          status
          nonResident
          basicSalary
          taxRelief
          backPay
          tierThreePercentage
          employeeType
          employeeBankDetails {
            id
            userId
            bankName
            accountName
            branchName
            accountNumber
            sortCode
            employeeTierThreePercent
          }
          employeeIdentification {
            id
            userId
            customSSN
            customTIN
            ssnitContributor
            ghanaCard
            nonResident
            tierTwo
            tierThree
          }
        }
        teamPerformance {
          id
          sixMonthsSalary
          tpBonus
          excessTpBonus
          tpTax
        }
        deductions {
          id
          employeeId
          employeeNumber
          description
          startDate
          endDate
          amountDue
          monthlyDeduction
          displayName
          totalDeduction
          date
        }
        bonuses {
          id
          employeeId
          employeeNumber
          description
          startDate
          endDate
          totalBonus
          monthlyBonus
          displayName
          date
          firstName
          lastName
        }
        allowances {
          id
          taxable
          employeeId
          employeeNumber
          description
          startDate
          endDate
          totalAllowance
          monthlyAllowance
          displayName
          date
        }
      }
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
    }
  }
`;

export const AMALITECH_TRAINING_REPORT = gql`
  query ListAmalitechTrainingPayroll(
    $page: Int
    $size: Int
    $searchName: String
    $yearMonth: String
  ) {
    listAmalitechTrainingPayroll(
      page: $page
      size: $size
      searchName: $searchName
      yearMonth: $yearMonth
    ) {
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
      data {
        payrollId
        pid
        month
        year
        basicSalary
        cashEmolument
        assessableIncome
        totalReliefs
        chargeableIncome
        totalTaxPayableToGRA
        employeeTierThreePre
        employerTierThreePre
        hazardous
        optionCode
        remarks
        employeeTierThreeAfter
        employerTierThreeAfter
        accommodationElement
        vehicleElement
        nonCashBenefit
        overtimeIncome
        overtimeTax
        severancePay
        initialGross
        totalAllowance
        totalTaxableAllowance
        totalNonTaxableAllowance
        grossSalary
        employeeSSF
        tierOne
        tierTwo
        taxRelief
        totalRelief
        taxableIncome
        paye
        loanDeduction
        totalDeduction
        netSalary
        employerSSF
        employeeCostToCompany
        bonusTotal
        totalNetBonus
        totalTaxOnBonus
        payableNetSalary
        excessBonus
        initialBonus
        backPay
        date
        studentLoanTrustFund
        deductions {
          id
          employeeId
          employeeNumber
          description
          startDate
          endDate
          amountDue
          monthlyDeduction
          displayName
          totalDeduction
          date
        }
        bonuses {
          id
          employeeId
          employeeNumber
          description
          startDate
          endDate
          totalBonus
          monthlyBonus
          displayName
          date
          firstName
          lastName
        }
        allowances {
          id
          taxable
          employeeId
          employeeNumber
          description
          startDate
          endDate
          totalAllowance
          monthlyAllowance
          displayName
          date
        }
        teamPerformance {
          id
          sixMonthsSalary
          tpBonus
          excessTpBonus
          tpTax
        }
        employee {
          id
          userId
          customPayrollID
          employeeId
          firstName
          middleName
          lastName
          displayName
          workEmail
          department
          jobTitle
          isManager
          secondaryEmployment
          organization
          employeePosition
          status
          nonResident
          basicSalary
          taxRelief
          backPay
          tierThreePercentage
          employeeType
        }
      }
    }
  }
`;

export const MONTHLY_PAYROLL_REPORT = gql`
  query ListPayrollsByMonthAndYear(
    $page: Int
    $size: Int
    $monthYear: MonthYearInput!
  ) {
    listPayrollsByMonthAndYear(
      page: $page
      size: $size
      monthYear: $monthYear
    ) {
      data {
        payrollId
        pid
        month
        year
        cashEmolument
        assessableIncome
        totalReliefs
        chargeableIncome
        totalTaxPayableToGRA
        employeeTierThreePre
        employerTierThreePre
        hazardous
        optionCode
        remarks
        employeeTierThreeAfter
        employerTierThreeAfter
        accommodationElement
        vehicleElement
        nonCashBenefit
        overtimeIncome
        overtimeTax
        severancePay
        initialGross
        totalAllowance
        totalTaxableAllowance
        totalNonTaxableAllowance
        grossSalary
        employeeSSF
        tierOne
        tierTwo
        taxRelief
        totalRelief
        taxableIncome
        paye
        loanDeduction
        totalDeduction
        netSalary
        employerSSF
        employeeCostToCompany
        bonusTotal
        totalNetBonus
        totalTaxOnBonus
        payableNetSalary
        excessBonus
        initialBonus
        backPay
        date
        studentLoanTrustFund
        deductions {
          id
          employeeId
          employeeNumber
          description
          startDate
          endDate
          monthlyDeduction
          displayName
          totalDeduction
          date
          amountDue
        }
        bonuses {
          id
          employeeId
          employeeNumber
          description
          startDate
          endDate
          totalBonus
          monthlyBonus
          displayName
          date
        }
        allowances {
          id
          taxable
          employeeId
          employeeNumber
          description
          startDate
          endDate
          totalAllowance
          monthlyAllowance
          displayName
          date
        }
        employee {
          id
          userId
          customPayrollID
          employeeId
          firstName
          middleName
          lastName
          displayName
          workEmail
          department
          jobTitle
          isManager
          secondaryEmployment
          organization
          employeePosition
          status
          nonResident
          basicSalary
          taxRelief
          backPay
          tierThreePercentage
          employeeType
          employeeBankDetails {
            id
            userId
            bankName
            accountName
            branchName
            accountNumber
            sortCode
            employeeTierThreePercent
          }
          employeeIdentification {
            id
            userId
            customSSN
            customTIN
            ssnitContributor
            ghanaCard
            nonResident
            tierTwo
            tierThree
          }
        }
        teamPerformance {
          id
          sixMonthsSalary
          tpBonus
          excessTpBonus
          tpTax
        }
        kpiBonus {
          id
          annualSalary
          kpBonus
          excessKpiBonus
          kpiTax
        }
      }
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
    }
  }
`;

export const MONTHLY_PAYROLL_REPORT_UNSORTED = gql`
  query GetEmployeePayrollReport($yearMonth: String!) {
    getEmployeePayrollReport(yearMonth: $yearMonth) {
      payrollId
      pid
      month
      year
      cashEmolument
      assessableIncome
      totalReliefs
      chargeableIncome
      totalTaxPayableToGRA
      employeeTierThreePre
      employerTierThreePre
      hazardous
      optionCode
      remarks
      employeeTierThreeAfter
      employerTierThreeAfter
      accommodationElement
      vehicleElement
      nonCashBenefit
      overtimeIncome
      overtimeTax
      severancePay
      initialGross
      totalAllowance
      totalTaxableAllowance
      totalNonTaxableAllowance
      grossSalary
      employeeSSF
      tierOne
      tierTwo
      taxRelief
      totalRelief
      taxableIncome
      paye
      loanDeduction
      totalDeduction
      netSalary
      employerSSF
      employeeCostToCompany
      bonusTotal
      totalNetBonus
      totalTaxOnBonus
      payableNetSalary
      excessBonus
      initialBonus
      backPay
      date
      studentLoanTrustFund
    }
  }
`;

export const EMPLOYEE_SORTED_PAYROLL_REPORT = gql`
  query GetSortedPayrollReportForEmployee(
    $sortInput: SortPayrollByMonthYearInput
  ) {
    getSortedPayrollReportForEmployee(sortInput: $sortInput) {
      payrollId
      pid
      month
      year
      cashEmolument
      assessableIncome
      totalReliefs
      chargeableIncome
      totalTaxPayableToGRA
      employeeTierThreePre
      employerTierThreePre
      hazardous
      optionCode
      remarks
      employeeTierThreeAfter
      employerTierThreeAfter
      accommodationElement
      vehicleElement
      nonCashBenefit
      overtimeIncome
      overtimeTax
      severancePay
      initialGross
      totalAllowance
      totalTaxableAllowance
      totalNonTaxableAllowance
      grossSalary
      employeeSSF
      tierOne
      tierTwo
      taxRelief
      totalRelief
      taxableIncome
      paye
      loanDeduction
      totalDeduction
      netSalary
      employerSSF
      employeeCostToCompany
      bonusTotal
      totalNetBonus
      totalTaxOnBonus
      payableNetSalary
      excessBonus
      initialBonus
      backPay
      date
      studentLoanTrustFund
      deductions {
        id
        employeeId
        employeeNumber
        description
        startDate
        endDate
        monthlyDeduction
        displayName
        totalDeduction
        date
        amountDue
      }
      bonuses {
        id
        employeeId
        employeeNumber
        description
        startDate
        endDate
        totalBonus
        monthlyBonus
        displayName
        date
      }
      allowances {
        id
        taxable
        employeeId
        employeeNumber
        description
        startDate
        endDate
        totalAllowance
        monthlyAllowance
        displayName
        date
      }
      employee {
        id
        userId
        customPayrollID
        employeeId
        firstName
        middleName
        lastName
        displayName
        workEmail
        department
        jobTitle
        isManager
        secondaryEmployment
        organization
        employeePosition
        status
        nonResident
        basicSalary
        taxRelief
        backPay
        tierThreePercentage
        employeeType
        employeeBankDetails {
          id
          userId
          bankName
          accountName
          branchName
          accountNumber
          sortCode
          employeeTierThreePercent
        }
        employeeIdentification {
          id
          userId
          customSSN
          customTIN
          ssnitContributor
          ghanaCard
          nonResident
          tierTwo
          tierThree
        }
      }
      teamPerformance {
        id
        sixMonthsSalary
        tpBonus
        excessTpBonus
        tpTax
      }
    }
  }
`;

export const LIST_PAYROLL_BY_NAME_DATE_RANGE = gql`
  query ListPayrollByNameAndDateRange(
    $page: Int
    $size: Int
    $searchName: String!
    $startDate: String!
    $endDate: String!
  ) {
    listPayrollByNameAndDateRange(
      page: $page
      size: $size
      searchName: $searchName
      startDate: $startDate
      endDate: $endDate
    ) {
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
      data {
        payrollId
        pid
        month
        year
        cashEmolument
        assessableIncome
        totalReliefs
        chargeableIncome
        totalTaxPayableToGRA
        employeeTierThreePre
        employerTierThreePre
        hazardous
        optionCode
        remarks
        employeeTierThreeAfter
        employerTierThreeAfter
        accommodationElement
        vehicleElement
        nonCashBenefit
        overtimeIncome
        overtimeTax
        severancePay
        initialGross
        totalAllowance
        totalTaxableAllowance
        totalNonTaxableAllowance
        grossSalary
        employeeSSF
        tierOne
        tierTwo
        taxRelief
        totalRelief
        taxableIncome
        paye
        loanDeduction
        totalDeduction
        netSalary
        employerSSF
        employeeCostToCompany
        bonusTotal
        totalNetBonus
        totalTaxOnBonus
        payableNetSalary
        excessBonus
        initialBonus
        backPay
        date
        studentLoanTrustFund
      }
    }
  }
`;

export const LIST_PAYROLL_BY_YEAR_MONTH = gql`
  query ListPayrollsByMonthAndYear(
    $monthYear: MonthYearInput!
    $page: Int
    $size: Int
  ) {
    listPayrollsByMonthAndYear(
      monthYear: $monthYear
      page: $page
      size: $size
    ) {
      data {
        payrollId
        pid
        month
        year
        cashEmolument
        assessableIncome
        totalReliefs
        chargeableIncome
        totalTaxPayableToGRA
        employeeTierThreePre
        employerTierThreePre
        hazardous
        optionCode
        remarks
        employeeTierThreeAfter
        employerTierThreeAfter
        accommodationElement
        vehicleElement
        nonCashBenefit
        overtimeIncome
        overtimeTax
        severancePay
        initialGross
        totalAllowance
        totalTaxableAllowance
        totalNonTaxableAllowance
        grossSalary
        employeeSSF
        tierOne
        tierTwo
        taxRelief
        totalRelief
        taxableIncome
        paye
        loanDeduction
        totalDeduction
        netSalary
        employerSSF
        employeeCostToCompany
        bonusTotal
        totalNetBonus
        totalTaxOnBonus
        payableNetSalary
        excessBonus
        initialBonus
        backPay
        date
        studentLoanTrustFund
        employee {
          id
          userId
          customPayrollID
          employeeId
          firstName
          middleName
          lastName
          displayName
          workEmail
          department
          jobTitle
          isManager
          secondaryEmployment
          organization
          employeePosition
          status
          nonResident
          basicSalary
          taxRelief
          backPay
          tierThreePercentage
          employeeType
          employeeBankDetails {
            id
            userId
            bankName
            accountName
            branchName
            accountNumber
            sortCode
            employeeTierThreePercent
          }
          employeeIdentification {
            id
            userId
            customSSN
            customTIN
            ssnitContributor
            ghanaCard
            nonResident
            tierTwo
            tierThree
          }
        }
      }
      pageable {
        size
        number
        totalPages
        totalElement
        empty
        first
        last
        sort
      }
    }
  }
`;
