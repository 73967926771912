import gql from "graphql-tag";

export const GET_PAYROLL_COLUMN_SUMMATIONS_WITH_DEPARTMENT = gql`
  query GetPayrollColumnSummationsWithDepartment($startWith: String) {
    getPayrollColumnSummationsWithDepartment(startWith: $startWith) {
      basicSalary
      allowance
      grossSalary
      initialGross
      employeeSSF
      employerSSF
      taxRelief
      totalRelief
      taxableIncome
      bonusTax
      paye
      deductions
      netSalary
      bonus
      payableNetSalary
      totalLoanDeduction
      backPay
      excessBonus
      initialBonus
      employeeCostToCompany
      studentLoanTrustFund
      totalTaxOnBonus
      cashEmolument
      assessableIncome
      totalReliefs
      chargeableIncome
      totalTaxPayableToGRA
      bonusTotal
      employeeTierThreePre
      employerTierThreePre
      employeeTierThreeAfter
      employerTierThreeAfter
      teamPerformance
      excessTeamPerformance
    }
  }
`;

export const GET_PAYROLL_COLUMN_SUMMATIONS_WITH_DEPARTMENT_AND_YEAR_MONTH_REPORT = gql`
  mutation GetDepartmentPayrollColumnSummationsReport(
    $yearMonth: String
    $startWith: String
  ) {
    getDepartmentPayrollColumnSummationsReport(
      yearMonth: $yearMonth
      startWith: $startWith
    ) {
      basicSalary
      allowance
      grossSalary
      initialGross
      employeeSSF
      employerSSF
      taxRelief
      totalRelief
      taxableIncome
      bonusTax
      paye
      deductions
      netSalary
      bonus
      payableNetSalary
      totalLoanDeduction
      backPay
      excessBonus
      initialBonus
      employeeCostToCompany
      studentLoanTrustFund
      totalTaxOnBonus
      cashEmolument
      assessableIncome
      totalReliefs
      chargeableIncome
      totalTaxPayableToGRA
      bonusTotal
      employeeTierThreePre
      employerTierThreePre
      employeeTierThreeAfter
      employerTierThreeAfter
      teamPerformance
      excessTeamPerformance
      kpiBonus
      excessKpiBonus
      sixMonthsSalary
      annualSalary
      tpTax
      kpiTax
    }
  }
`;

export const GET_PAYROLL_COLUMN_SUMMATIONS = gql`
  mutation GetPayrollColumnSummationsReport($yearMonth: String) {
    getPayrollColumnSummationsReport(yearMonth: $yearMonth) {
      basicSalary
      allowance
      grossSalary
      initialGross
      employeeSSF
      employerSSF
      taxRelief
      totalRelief
      taxableIncome
      bonusTax
      paye
      deductions
      netSalary
      bonus
      payableNetSalary
      totalLoanDeduction
      backPay
      excessBonus
      initialBonus
      employeeCostToCompany
      studentLoanTrustFund
      totalTaxOnBonus
      cashEmolument
      assessableIncome
      totalReliefs
      chargeableIncome
      totalTaxPayableToGRA
      bonusTotal
      employeeTierThreePre
      employerTierThreePre
      employeeTierThreeAfter
      employerTierThreeAfter
      teamPerformance
      excessTeamPerformance
    }
  }
`;

export const GET_AMALITECH_GH_PAYROLL_COLUMN_SUMMATIONS = gql`
  mutation GetAmalitechGhanaPayrollColumnSummationsReport($yearMonth: String) {
    getAmalitechGhanaPayrollColumnSummationsReport(yearMonth: $yearMonth) {
      basicSalary
      allowance
      grossSalary
      initialGross
      employeeSSF
      employerSSF
      taxRelief
      totalRelief
      taxableIncome
      bonusTax
      paye
      deductions
      netSalary
      bonus
      payableNetSalary
      totalLoanDeduction
      backPay
      excessBonus
      initialBonus
      employeeCostToCompany
      studentLoanTrustFund
      totalTaxOnBonus
      cashEmolument
      assessableIncome
      totalReliefs
      chargeableIncome
      totalTaxPayableToGRA
      bonusTotal
      employeeTierThreePre
      employerTierThreePre
      employeeTierThreeAfter
      employerTierThreeAfter
      teamPerformance
      excessTeamPerformance
    }
  }
`;

export const GET_NSP_PAYROLL_COLUMN_SUMMATIONS = gql`
  query ListCurrentNspPayrollColumnSummations($yearMonth: String) {
    getCurrentNspPayrollColumnSummations(yearMonth: $yearMonth) {
      monthlyNspAllowance
      monthlyAmalitechAllowance
      monthlyAllowancePayable
      monthlyCharge
      yearlyNspAllowance
      yearlyNspAllowanceCharge
      monthlyAllowanceTax
    }
  }
`;

export const GET_TIER_ONE_SUMMATIONS_BY_DEPARTMENT = gql`
  query GetTierOneSummationByDepartment(
    $startWith: String
    $yearMonth: String
  ) {
    getTierOneSummationByDepartment(
      startWith: $startWith
      yearMonth: $yearMonth
    ) {
      sumBasicSalary
      sumPercentage
    }
  }
`;

export const GET_TIER_TWO_SUMMATIONS_BY_DEPARTMENT = gql`
  query GetTierTwoSummationByDepartment(
    $startWith: String
    $yearMonth: String
  ) {
    getTierTwoSummationByDepartment(
      startWith: $startWith
      yearMonth: $yearMonth
    ) {
      sumBasicSalary
      sumPercentage
    }
  }
`;

export const GET_TIER_ONE_SUMMATION_REPORT = gql`
  mutation GetTierOneSummationReport($yearMonth: String) {
    getTierOneSummationReport(yearMonth: $yearMonth) {
      sumBasicSalary
      sumPercentage
    }
  }
`;

export const GET_TIER_TWO_SUMMATION_REPORT = gql`
  mutation GetTierTwoSummationReport($yearMonth: String) {
    getTierTwoSummationReport(yearMonth: $yearMonth) {
      sumBasicSalary
      sumPercentage
    }
  }
`;

export const GET_BONUS_SUMMATIONS_BY_DEPARTMENT = gql`
  query ListBonusTotals($startWith: String, $yearMonth: String) {
    getBonusTotals(startWith: $startWith, yearMonth: $yearMonth) {
      bonus
      netBonus
      bonusTax
    }
  }
`;

export const GET_JV_REPORT_BY_DEPARTMENT = gql`
  mutation GetJVReportByDepartment($startWith: String, $yearMonth: String) {
    getJVReportByDepartment(startWith: $startWith, yearMonth: $yearMonth) {
      grossSalary
      incomeTax
      socialSecurity
      socialSecurityFund
      loanDeduction
      netSalary
      creditTotals
      studentLoanDeduction
      tierThree
    }
  }
`;

export const GET_NSP_JV_REPORT = gql`
  mutation GetNspJVReportByDepartment($yearMonth: String) {
    getNspJVReportByDepartment(yearMonth: $yearMonth) {
      grossAllowance
      nspMonthlyCharge
      netPayrollControl
      debitTotal
      creditTotal
    }
  }
`;

export const GET_JV_REPORT = gql`
  query GetJVReport {
    getJVReport {
      tierThree
      studentLoanDeduction
      creditTotals
      netSalary
      loanDeduction
      socialSecurityFund
      socialSecurity
      incomeTax
      grossSalary
    }
  }
`;

export const GET_NSP_AMORTIZATION_REPORT = gql`
  query GetNspAmortizationReport($yearMonth: String) {
    getNspAmortizationReport(yearMonth: $yearMonth) {
      totalCharge
      amortizations {
        date
        charge
      }
    }
  }
`;
