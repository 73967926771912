<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.9402 2.21178L9.5302 7.82178H7.1202C6.7202 7.82178 6.3302 7.85178 5.9502 7.93178L6.9502 5.53178L6.9902 5.44178L7.0502 5.28178C7.0802 5.21178 7.1002 5.15178 7.1302 5.10178C8.2902 2.41178 9.5902 1.57178 11.9402 2.21178Z" fill="#DD5928"/>
        <path d="M18.7301 8.08953L18.7101 8.07953C18.1101 7.90953 17.5001 7.81953 16.8801 7.81953H10.6201L12.8701 2.58953L12.9001 2.51953C13.0401 2.56953 13.1901 2.63953 13.3401 2.68953L15.5501 3.61953C16.7801 4.12953 17.6401 4.65953 18.1701 5.29953C18.2601 5.41953 18.3401 5.52953 18.4201 5.65953C18.5101 5.79953 18.5801 5.93953 18.6201 6.08953C18.6601 6.17953 18.6901 6.25953 18.7101 6.34953C18.8601 6.85953 18.8701 7.43953 18.7301 8.08953Z" fill="#DD5928"/>
        <path d="M12.52 17.6581H12.77C13.07 17.6581 13.32 17.3881 13.32 17.0581C13.32 16.6381 13.2 16.5781 12.94 16.4781L12.52 16.3281V17.6581Z" fill="#C1C4D6"/>
        <path d="M18.2902 9.52031C17.8402 9.39031 17.3702 9.32031 16.8802 9.32031H7.12023C6.44023 9.32031 5.80023 9.45031 5.20023 9.71031C3.46023 10.4603 2.24023 12.1903 2.24023 14.2003V16.1503C2.24023 16.3903 2.26023 16.6203 2.29023 16.8603C2.51023 20.0403 4.21023 21.7403 7.39023 21.9503C7.62023 21.9803 7.85023 22.0003 8.10023 22.0003H15.9002C19.6002 22.0003 21.5502 20.2403 21.7402 16.7403C21.7502 16.5503 21.7602 16.3503 21.7602 16.1503V14.2003C21.7602 11.9903 20.2902 10.1303 18.2902 9.52031ZM13.2802 15.5003C13.7402 15.6603 14.3602 16.0003 14.3602 17.0603C14.3602 17.9703 13.6502 18.7003 12.7702 18.7003H12.5202V18.9203C12.5202 19.2103 12.2902 19.4403 12.0002 19.4403C11.7102 19.4403 11.4802 19.2103 11.4802 18.9203V18.7003H11.3902C10.4302 18.7003 9.64023 17.8903 9.64023 16.8903C9.64023 16.6003 9.87023 16.3703 10.1602 16.3703C10.4502 16.3703 10.6802 16.6003 10.6802 16.8903C10.6802 17.3103 11.0002 17.6603 11.3902 17.6603H11.4802V15.9703L10.7202 15.7003C10.2602 15.5403 9.64023 15.2003 9.64023 14.1403C9.64023 13.2303 10.3502 12.5003 11.2302 12.5003H11.4802V12.2803C11.4802 11.9903 11.7102 11.7603 12.0002 11.7603C12.2902 11.7603 12.5202 11.9903 12.5202 12.2803V12.5003H12.6102C13.5702 12.5003 14.3602 13.3103 14.3602 14.3103C14.3602 14.6003 14.1302 14.8303 13.8402 14.8303C13.5502 14.8303 13.3202 14.6003 13.3202 14.3103C13.3202 13.8903 13.0002 13.5403 12.6102 13.5403H12.5202V15.2303L13.2802 15.5003Z" fill="#C1C4D6"/>
        <path d="M10.6802 14.1391C10.6802 14.5591 10.8002 14.6191 11.0602 14.7191L11.4802 14.8691V13.5391H11.2302C10.9202 13.5391 10.6802 13.8091 10.6802 14.1391Z" fill="#C1C4D6"/>
    </svg>
</template>

<script>
export default {
    props:{
    width: {
        type: Number,
        default: 16
    },
    height: {
        type: Number,
        default: 16
    },
    color: {
        type: String,
    }
}

};
</script>