import axios from "axios";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

export async function loginUser(userData) {
  try {
    setAuthToken(userData.token);
    setCurrentUser(userData);
    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
}

export function logoutUser() {
  clearAuthToken();
  clearCurrentUser();
}

export function setAuthToken(token) {
  if (token) {
    const decodedToken = jwtDecode(token);
    const time = Number(Date.now());
    const expires = Number(decodedToken.exp) * 1000;
    const expiringTime = Math.floor((expires - time) / 1000);
    expiringTime > 0 &&
      Cookies.set("authToken", token, { expires: expiringTime });
  }
}

export function getAuthToken() {
  return Cookies.get("authToken");
}

export function clearAuthToken() {
  axios.defaults.headers.common.Authorization = "";
  return Cookies.remove("authToken");
}

export function isLoggedIn() {
  const authToken = getAuthToken();
  const currentUser = getCurrentUser();
  return !!(authToken && isTokenActive(parseInt(currentUser.expiresIn)));
}

export function setCurrentUser(data) {
  Cookies.set("currentUser", JSON.stringify(data));
}

export function getCurrentUser() {
  const currentUser = Cookies.get("currentUser");
  if (currentUser) {
    return JSON.parse(currentUser);
  }
  return {};
}

export function clearCurrentUser() {
  return Cookies.remove("currentUser");
}

export function isSuperUser() {
  return isLoggedIn();
}
export function getUserRole() {
  if (isLoggedIn()) {
    return getCurrentUser()?.role;
  } else {
    return [];
  }
}

export function isTokenActive(date) {
  const now = Math.floor(Date.now() / 1000);
  return date > now;
}
