import { apolloClient } from "../../../apollo/apolloVueClient";
import {
	CREATE_DEDUCTIONS,
	DELETE_DEDUCTIONS,
	UPDATE_DEDUCTIONS,
} from "../../../graphql/mutations/deductions_mutations";
import {
	LIST_DEDUCTIONS,
	LIST_DEDUCTIONS_BY_NAME,
} from "../../../graphql/queries/all_queries";

export default {
	namespaced: true,
	// initial state
	state: {
		deductions: [],
		currentPage: null,
		totalPages: null,
	},
	//getters
	getters: {
		allDeductions: (state) => {
			return state.deductions;
		},
		currentPage: (state) => {
			return state.currentPage || 1;
		},
		totalPages: (state) => {
			return state.totalPages;
		},
	},
	// mutations
	mutations: {
		ADD_DEDUCTIONS(state, payload) {
			let deductions = state.deductions.concat(payload);
			state.deductions = deductions;
		},

		DELETE_DEDUCTION(state, deductionID) {
			let deductions = state.deductions.filter(
				(emp) => emp.id != deductionID
			);
			state.deductions = deductions;
		},

		SET_DEDUCTION(state, payload) {
			state.deductions = payload;
		},
		SET_CURRENT_PAGE(state, payload) {
			state.currentPage = payload + 1;
		},
		SET_TOTAL_PAGE(state, payload) {
			state.totalPages = payload;
		},
		EDIT_DEDUCTION(state, payload) {
			state.deductions = state.deductions.map((emp) => {
				if (emp.id == payload.uid) {
					return payload;
				}
				return emp;
			});
		},
		SEARCH_DEDUCTION(state, payload) {
			state.deductions = payload;
		},
	},

	// actions
	actions: {
		//CREATE DEDUCTIONS ON SERVER
		async createDeduction(context, payload) {
			return new Promise(function (resolve, reject) {
				// api.post(endpoint.addDeductions, payload)
				apolloClient
					.mutate({
						mutation: CREATE_DEDUCTIONS,
						variables: {
							createDeductionInput: payload,
						},
					})
					.then(() => {
						resolve();
					})
					.catch((error) => {
						reject(error);
					});
			});
		},

		//GET ALL DEDUCTION FROM THE SERVER
		async fetchDeduction({ commit }, page) {
			try {
				let { data } = await apolloClient.query({
					query: LIST_DEDUCTIONS,
					fetchPolicy: "network-only",
					variables: {
						size: 10,
						page: page - 1,
					},
				});
				commit("SET_DEDUCTION", data.listDeductions.data);
				commit("SET_TOTAL_PAGE", data.listDeductions.pageable.totalPages);
				commit("SET_CURRENT_PAGE", data.listDeductions.pageable.number);
			} catch (error) {
				console.log(error);
			}
		},

		editDeduction(context, payload) {
			return new Promise((resolve, reject) => {
				// api.patch(endpoint.editDeductionsEndpoint, payload)
				apolloClient
					.mutate({
						mutation: UPDATE_DEDUCTIONS,
						variables: {
							updateDeductionInput: payload,
						},
					})
					.then(() => {
						context.dispatch("fetchDeduction", 1).then(() => {
							resolve({ message: "Deduction Updated Successfully" });
						});
					})
					.catch((error) => {
						reject(error);
					});
			});
		},

		//DELETE DEDUCTION FROM SERVER
		deleteDeduction(_context, payload) {
			return new Promise((resolve, reject) => {
				// api.post(endpoint.deleteDeductionEndpoints, payload)
				apolloClient
					.mutate({
						mutation: DELETE_DEDUCTIONS,
						variables: {
							deleteByIdInput: payload,
						},
					})
					.then((response) => {
						resolve(response);
					})
					.catch((error) => {
						reject(error);
					});
			});
		},

		//SEARCH DEDUCTION ON SERVER
		async searchDeductions({ commit }, query) {
			try {
				let response = await apolloClient.query({
					query: LIST_DEDUCTIONS_BY_NAME,
					variables: {
						searchByDisplayNameInput: query.trim(),
						size: 30,
						page: 0,
					},
				});
				let queryResult = response.data.listDeductionsByName;
				commit("SEARCH_DEDUCTION", queryResult.data);
				commit("SET_TOTAL_PAGE", queryResult.pageable.totalPages);
				commit("SET_CURRENT_PAGE", queryResult.pageable.pageNumber);
			} catch (error) {
				console.log(error);
			}
		},
	},
};
