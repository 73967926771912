import gql from "graphql-tag";

export const GENERATE_PAYROLL = gql`
  mutation GenerateNewPayrolls($user: String) {
    generateNewPayrolls(user: $user) {
      message
      success
      code
      data {
        payrollId
        pid
        month
        year
        cashEmolument
        assessableIncome
        totalReliefs
        chargeableIncome
        totalTaxPayableToGRA
        employeeTierThreePre
        employerTierThreePre
        hazardous
        optionCode
        remarks
        employeeTierThreeAfter
        employerTierThreeAfter
        accommodationElement
        vehicleElement
        nonCashBenefit
        overtimeIncome
        overtimeTax
        severancePay
        initialGross
        totalAllowance
        totalTaxableAllowance
        totalNonTaxableAllowance
        grossSalary
        employeeSSF
        tierOne
        tierTwo
        taxRelief
        totalRelief
        taxableIncome
        paye
        loanDeduction
        totalDeduction
        netSalary
        employerSSF
        employeeCostToCompany
        bonusTotal
        totalNetBonus
        totalTaxOnBonus
        payableNetSalary
        excessBonus
        initialBonus
        backPay
        date
        studentLoanTrustFund
      }
    }
  }
`;

export const AUTHORIZE_PAYROLL = gql`
  mutation AuthorizePayroll($statusInput: AuthorizePayrollInput) {
    authorizePayroll(statusInput: $statusInput) {
      message
      success
      code
      data {
        psid
        authorized
        approved
        generated
        authorizedBy
        approvedBy
        generatedBy
        generatedDate
        authorizedDate
        approvedDate
        date
      }
    }
  }
`;

export const APPROVE_PAYROLL = gql`
  mutation ApprovePayroll($statusInput: ApprovePayrollInput) {
    approvePayroll(statusInput: $statusInput) {
      message
      success
      code
      data {
        psid
        authorized
        approved
        generated
        authorizedBy
        approvedBy
        generatedBy
        generatedDate
        authorizedDate
        approvedDate
        date
      }
    }
  }
`;

export const DOWNLOAD_EMPLOYEE_PAYROLL = gql`
  mutation GetEmployeePayslip($downloadPayslipInput: DownloadPayslipInput) {
    getEmployeePayslip(downloadPayslipInput: $downloadPayslipInput) {
      message
      success
      code
      data {
        payrollId
        pid
        month
        year
        cashEmolument
        assessableIncome
        totalReliefs
        chargeableIncome
        totalTaxPayableToGRA
        employeeTierThreePre
        employerTierThreePre
        hazardous
        optionCode
        remarks
        employeeTierThreeAfter
        employerTierThreeAfter
        accommodationElement
        vehicleElement
        nonCashBenefit
        overtimeIncome
        overtimeTax
        severancePay
        initialGross
        totalAllowance
        totalTaxableAllowance
        totalNonTaxableAllowance
        grossSalary
        employeeSSF
        tierOne
        tierTwo
        taxRelief
        totalRelief
        taxableIncome
        paye
        loanDeduction
        totalDeduction
        netSalary
        employerSSF
        employeeCostToCompany
        bonusTotal
        totalNetBonus
        totalTaxOnBonus
        payableNetSalary
        excessBonus
        initialBonus
        backPay
        date
        studentLoanTrustFund
      }
    }
  }
`;

export const EXPORT_TIER_THREE_FOR_DEPT = gql`
  query ExportCurrentTierThreeForDepartment($startWith: String) {
    exportCurrentTierThreeForDepartment(startWith: $startWith) {
      payrollId
      pid
      month
      year
      cashEmolument
      assessableIncome
      totalReliefs
      chargeableIncome
      totalTaxPayableToGRA
      employeeTierThreePre
      employerTierThreePre
      hazardous
      optionCode
      remarks
      employeeTierThreeAfter
      employerTierThreeAfter
      accommodationElement
      vehicleElement
      nonCashBenefit
      overtimeIncome
      overtimeTax
      severancePay
      initialGross
      totalAllowance
      totalTaxableAllowance
      totalNonTaxableAllowance
      grossSalary
      employeeSSF
      tierOne
      tierTwo
      taxRelief
      totalRelief
      taxableIncome
      paye
      loanDeduction
      totalDeduction
      netSalary
      employerSSF
      employeeCostToCompany
      bonusTotal
      totalNetBonus
      totalTaxOnBonus
      payableNetSalary
      excessBonus
      initialBonus
      backPay
      date
      studentLoanTrustFund
    }
  }
`;

export const RESET_PAYROLL_STATUS = gql`
  mutation rollbackPayroll($year: Int, $month: Int) {
    rollbackPayroll(year: $year, month: $month) {
      success
      message
      code
    }
  }
`;
