import gql from "graphql-tag";

export const EXPORT_TIER_ONE_REPORT = gql`
  query ExportTierOneReport(
    $employeeName: String
    $yearMonth: String
    $startWith: String
  ) {
    exportTierOneReport(
      employeeName: $employeeName
      yearMonth: $yearMonth
      startWith: $startWith
    )
  }
`;

export const EXPORT_TIER_TWO_REPORT = gql`
  query ExportTierTwoReport(
    $employeeName: String
    $yearMonth: String
    $startWith: String
  ) {
    exportTierTwoReport(
      employeeName: $employeeName
      yearMonth: $yearMonth
      startWith: $startWith
    )
  }
`;

export const EXPORT_GRA_REPORT = gql`
  query ExportGRAReport(
    $employeeName: String
    $yearMonth: String
    $startWith: String
  ) {
    exportGRAReport(
      employeeName: $employeeName
      yearMonth: $yearMonth
      startWith: $startWith
    )
  }
`;

export const EXPORT_BANK_ADVICE_BY_DEPARTMENT = gql`
  query ExportBankAdviseByDateExcel(
    $employeeName: String
    $yearMonth: String
    $startWith: String
  ) {
    exportBankAdviseByDateExcel(
      employeeName: $employeeName
      yearMonth: $yearMonth
      startWith: $startWith
    )
  }
`;

export const EXPORT_BANK_ADVICE_EXCEL = gql`
query ExportBankAdviseExcel($startWith: String) {
  exportBankAdviseExcel(startWith: $startWith)
}
`

export const NSP_INDIVIDUAL_PAYSLIP = gql`
  query GetSortedPayrollReportForNSP(
    $sortInput: SortPayrollByMonthYearInput
  ) {
    getSortedPayrollReportForNSP(
      sortInput: $sortInput
    ) {
      payrollId
      pid
      month
      year
      monthlyNspAllowance
      monthlyAmalitechAllowance
      monthlyAllowancePayable
      monthlyCharge
      yearlyNspAllowance
      yearlyNspAllowanceCharge
      monthlyAllowanceTax
      employee {
        id
        userId
        customPayrollID
        employeeId
        firstName
        middleName
        lastName
        displayName
        workEmail
        department
        jobTitle
        isManager
        secondaryEmployment
        organization
        employeePosition
        status
        nonResident
        basicSalary
        taxRelief
        backPay
        tierThreePercentage
        employeeType
        employeeBankDetails {
          id
          userId
          bankName
          accountName
          branchName
          accountNumber
          sortCode
          employeeTierThreePercent
        }
        employeeIdentification {
          id
          userId
          customSSN
          customTIN
          ssnitContributor
          ghanaCard
          nonResident
          tierTwo
          tierThree
        }
      }
    }
  }
`;