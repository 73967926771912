import gql from "graphql-tag";

export const CREATE_DEFINITIONS = gql`
	mutation CreateTag($tagInput: TagInput) {
		createTag(tagInput: $tagInput) {
			message
			success
			code
			data {
				tagId
				tag
				tagType
			}
		}
	}
`;
export const UPDATE_DEFINITIONS = gql`
	mutation UpdateTag($tagInput: TagInput) {
		updateTag(tagInput: $tagInput) {
			message
			success
			code
			data {
				tagId
				tag
				tagType
			}
		}
	}
`;
export const DELETE_DEFINITIONS = gql`
	mutation DeleteTag($id: ID!) {
		deleteTag(id: $id) {
			message
			success
			code
		}
	}
`;
