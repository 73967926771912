<template>
  <v-app>
    <v-main>
      <v-container>
        <v-layout align-center justify-center class="my-16">
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-1 pt-14 pb-7 px-3">
              <h2 class="font-weight-bold text-center">AMALITECH PAYROLL</h2>
              <p class="text-center" v-if="!isError">
                Welcome! Please wait while we authenticate your credentials
              </p>
              <h4 class="text-center font-weight-bold background-danger" v-else>
                Authenticating...
              </h4>
              <v-card-text class="d-flex justify-content-center">
                <!-- dispalay notification message {toast}  -->
                <v-snackbar
                  :timeout="3000"
                  :value="true"
                  absolute
                  top
                  :color="isError ? 'error' : 'success'"
                  center
                  text
                  v-model="snackBar"
                >
                  {{ snackBarText }}
                </v-snackbar>

                <div
                  v-if="isLoading"
                  class="d-flex justify-content-center w-100 text-center"
                >
                  <v-progress-circular
                    indeterminate
                    size="100"
                    color="primary"
                    class="justify-content-center text-center"
                  ></v-progress-circular>
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import * as auth from "../services/auth";
import config from "../../public/config";
import jwt_decode from "jwt-decode";
import gql from "graphql-tag";
import Cookie from "js-cookie";

export default {
  data() {
    return {
      isLoading: true,
      isError: false,
      snackBar: false,
      snackBarText: "",
      showPassword: false,
      email: "",
      password: "",
      token: "",
      queryToken: "",
    };
  },

  beforeRouteEnter(to, from, next) {
    if (to?.query["app-token"]) {
      localStorage.setItem("app-token", to.query["app-token"]);
      Cookie.set("app-token", to.query["app-token"]);
      Cookie.set("login-hint", to.query["login-hint"]);
    } else {
      window.location.replace(config.apiBaseUrl + "/login");
    }
    next((vm) => {
      if (!vm.$route.query.data) {
        vm.isLoading = true;
        vm.$apollo
          .query({
            query: gql`
              query PayrollAuth($token: String!) {
                payrollAuth(token: $token)
              }
            `,
            variables: {
              token: localStorage.getItem("app-token"),
            },
          })
          .then((res) => {
            vm.setAuthData(res.data.payrollAuth);
            vm.isLoading = false;
          })
          .catch((err) => {
            console.log("err::::", err);
            vm.isError = true;
            setTimeout(() => {
              window.location.replace("/not_authorized");
            }, 3000);
          })
          .finally(() => {
            vm.isLoading = false;
            setTimeout(() => {
              localStorage.removeItem("app-token");
            }, 3000);
          });
      }
    });
  },
  methods: {
    setAuthData(token) {
      if (token && token !== "") {
        let data = jwt_decode(token);
        let userData = {
          name: data.name,
          token: token,
          email: data.email,
          prevUrl: data.prevUrl,
          expiresIn: data.exp,
          role: data.role,
        };

        this.isLoading = true;
        auth
          .loginUser(userData)
          .then(() => {
            this.isLoading = false;
            this.snackBar = true;
            this.snackBarText = "Authentication Successful. Please Wait...";
            this.$store.commit("auth/SET_CURRENT_USER", userData);
            setTimeout(() => {
              window.location.href = "/employee";
            }, 2000);
          })
          .catch(() => {
            this.isLoading = false;
            this.isError = true;
            this.snackBar = true;
            this.snackBarText = "Authentication Failed";
            setTimeout(() => {
              window.location.replace("/not_authorized");
            }, 3000);
          });
      } else {
        this.isLoading = false;
        this.isError = true;
        this.snackBar = true;
        this.snackBarText = "Login Failed. Please Wait...";

        setTimeout(() => {
          window.location.replace("/not_authorized");
        }, 3000);
      }
    },
  },
};
</script>

<style scoped>
h2 {
  font-size: 1.7rem;
  color: #cf4f1f;
}
p {
  font-size: 0.8rem;
}
a {
  text-decoration: none;
}
.v-input__prepend-inner {
  background-color: #cf4f1f;
}
input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: #fff !important;
  color: -internal-light-dark(black, white) !important;
}
</style>
