import gql from "graphql-tag";

export const ADD_PENSIONS = gql`
  mutation AddPensions($pensionInput: [PensionInput!]!) {
    addPensions(pensionInput: $pensionInput) {
      id
      label
      percentage
    }
  }
`;

export const UPDATE_PENSION = gql`
  mutation UpdatePension($input: PensionInput) {
    updatePension(pensionInput: $input) {
      message
      success
      code
      data {
        id
        label
        percentage
      }
    }
  }
`;

export const DELETE_PENSION = gql`
  mutation DeletePension($pensionInput: PensionInput) {
    deletePension(pensionInput: $pensionInput) {
      message
      success
      code
      data {
        id
        label
        percentage
      }
    }
  }
`;
