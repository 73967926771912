import gql from "graphql-tag";

export const CREATE_DEDUCTIONS = gql`
	mutation CreateDeductions($createDeductionInput: [CreateDeductionInput!]!) {
		createDeductions(createDeductionInput: $createDeductionInput) {
			message
			success
			code
		}
	}
`;

export const UPDATE_DEDUCTIONS = gql`
	mutation UpdateDeductions($updateDeductionInput: [UpdateDeductionInput!]!) {
		updateDeductions(updateDeductionInput: $updateDeductionInput) {
			message
			success
			code
		}
	}
`;

export const DELETE_DEDUCTIONS = gql`
	mutation DeleteDeductions($deleteByIdInput: [DeleteByIdInput!]!) {
		deleteDeductions(deleteByIdInput: $deleteByIdInput) {
			message
			success
			code
		}
	}
`;
