import gql from "graphql-tag";

export const CREATE_TAX = gql`
  mutation CreatePayeConfigs($input: [PayeConfigInput!]!) {
    createPayeConfigs(payeConfigInput: $input) {
      message
      success
      code
      data{
        id
        label
        percentage
        amount
      }
    }
  }
`;

export const UPDATE_TAXES = gql`
  mutation UpdatePayeConfig($input: PayeConfigInput) {
    updatePayeConfig(payeConfigInput: $input) {
      message
      success
      code
      data {
        id
        label
        percentage
        amount
      }
    }
  }
`;

export const DELETE_TAXES = gql`
  mutation DeletePayeConfig($id: ID!) {
    deletePayeConfig(id: $id) {
      message
      success
      code
    }
  }
`;
