import '@mdi/font/css/materialdesignicons.css' 
import 'font-awesome/css/font-awesome.min.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'


Vue.use(Vuetify);
const defIcon = 'mdi';
export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#cf4f1f',
        secondary: '#b0bec5',
        accent: '#43425D',
        footer: '#F5F6FA',
        error: '#b71c1c',
       // success:'#8E9926',
        // anchor: '#cf4f1f',
      },
    },
  },
  icons: {
    iconfont: defIcon ? 'mdi' : 'fa',
  },
});
