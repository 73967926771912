import {
	ApolloClient,
	InMemoryCache,
	createHttpLink,
} from "@apollo/client/core";
import config from "../../../public/config";
import { ApolloProvider } from "vue-apollo";
import Cookies from "js-cookie";

const authToken = Cookies.get("authToken");

const httpLink = createHttpLink(
	authToken
		? {
				uri: config.apiGraphqlBaseUrl,
				headers: {
					Authorization: "Bearer " + Cookies.get("authToken"),
			},
		}
		: {
			uri: config.apiGraphqlBaseUrl,
		}
);

const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
	cache,
	link: httpLink,
});


const apolloProvider = new ApolloProvider({
	defaultClient: apolloClient,
});

export default apolloProvider;
